import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ErrorBag } from 'src/models/ErrorModel';
import { FORM } from 'src/constants/Form';
import { TextField, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { t } from 'src/lib/language';
import { useEffect, useState } from 'react';
import {
  getUserBankAccountAction,
  getUserCreditCardAction,
} from 'src/redux/actions/paymentInfoAction';
import { size } from 'lodash';
import { PAYMENT_TYPE } from 'src/models/PaymentModel';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  disabled?: boolean,
  form: FORM,
  margin?: 'dense' | 'normal' | undefined,
  name: string,
  noMarginTop?: boolean
  onChange: (id:number) => void,
  style?: CSSProperties,
  type: PAYMENT_TYPE,
  value?: number,
}

interface DataItem {
  id: number,
  label?: string,
  description?: string,
}

const UserPaymentAccountSelector = (props: Props) => {
  const dispatch = useAppDispatch();

  const {
    disabled,
    form,
    margin,
    name,
    onChange,
    style,
    type,
    value,
  } = props;

  const accounts = useSelector((state: Store) => ((PAYMENT_TYPE.eft === type)
    ? state.userBankAccounts
    : state.userCreditCards
  ));

  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const errors: ErrorBag =
    useSelector((state:Store) => state.errors[form] || {} as ErrorBag);
  const [getLabelState, setLabelState] = useState(PAYMENT_TYPE.eft);

  useEffect(() => {
    switch (type) {
      case PAYMENT_TYPE.cc:
        dispatch(getUserCreditCardAction());
        setLabelState(PAYMENT_TYPE.cc);
        break;
      default:
        dispatch(getUserBankAccountAction());
        setLabelState(PAYMENT_TYPE.eft);
    }
  }, [
    type,
  ]);

  const getItems = () => {
    const items:DataItem[] = [{ id: 0 }];
    if (!accounts || !size(accounts)) {
      return items;
    }

    accounts.forEach((item: any) => {
      items.push({
        id: item.id,
        label: (PAYMENT_TYPE.cc === type)
          ? `${item.masked_card_number} Exp ${item.expiration_data}`
          : `${item.name} # ${item.account}`,
        description: item.name,
      } as DataItem);
    });

    return items;
  };

  const onValueChange = (item: DataItem) => {
    if (!item) {
      onChange(0);
      return null;
    }
    onChange(item.id as number);
    return null;
  };

  const getValue = (initialValue?: number) => {
    let result = {
      id: 0,
      label: undefined,
    } as DataItem;
    if (!accounts || !size(accounts)) {
      return result;
    }

    accounts.forEach((item: any) => {
      if (item.id === initialValue) {
        result = {
          id: item.id,
          label: (PAYMENT_TYPE.cc === type)
            ? `${item.masked_card_number} Exp ${item.expiration_data}`
            : `${item.name} # ${item.account}`,
        } as DataItem;
      }
    });
    return result;
  };

  const displayLabel = (option:DataItem) => {
    if (option.label) {
      return (
        <Tooltip
          title={option.description as string}
          placement="top-start">
          <span>{ option.label }</span>
        </Tooltip>
      );
    }
    return (<></>);
  };

  const getOptionLabel = (option: DataItem) => option.label || '';

  return (
    <Autocomplete
      style={style}
      value={getValue(value)}
      onChange={(e, item) => onValueChange(item as DataItem)}
      options={getItems() || []}
      disabled={disabled}
      getOptionLabel={(option: DataItem) => getOptionLabel(option)}
      getOptionSelected={(option, item) => option.id === item.id}
      renderOption={(option: DataItem) => displayLabel(option)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={t(lang, `forms.payments.contact_${getLabelState}`)}
          placeholder={t(lang, `forms.payments.contact_${getLabelState}`)}
          error={!!errors[name]}
          margin={margin || 'normal'} />
      )} />
  );
};

export default UserPaymentAccountSelector;
