import { Route } from 'src/constants/routes';

const titles = {
  [Route.CONTACTS]: 'Contacts',
  [Route.INVOICES]: 'Factures',
  [Route.PAYMENTS]: 'Paiements',
  [Route.FUNDRAISING]: 'Evenements',
  [Route.SETTINGS]: 'Configurations',
  [Route.INVOICES_PAYMENT]: 'Factures pour Paiement',
  [Route.INVOICES_CONTACT]: 'Factures pour Contact',
  [Route.PAYMENTS_INVOICE]: 'Paiements pour Factures',
  [Route.PAYMENTS_CONTACT]: 'Paiements pour Contact',
  [Route.ORGANISATIONS]: 'Organisations',
};

export default titles;
