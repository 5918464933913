import {
  Filter,
  FilterOperator,
  Filters,
  SEARCH_LOGICAL_OPERATORS,
} from 'src/models/AdvanceSearchModel';
import {
  OPERATOR_EXTRA_TYPE,
  SEARCH_OPERATORS,
  SEARCH_OPERATORS_PRIMITIVE,
} from 'src/constants/SearchOperators';
import { FILTER_POSITION } from 'src/constants/FilterPosition';
import { Moment } from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import {
  ContactQuickSearch,
  InvoiceQuickSearch,
  PaymentQuickSearch,
} from 'src/models/QuickSearchModel';
import {
  createInvoiceSearchTermsFilter,
  createPaymentSearchTermsFilter,
} from 'src/lib/PaymentHelper';
import { ENTITIES } from 'src/models/QuerybuilderModel';

export const normalizeForForm = (row: any, entity: ENTITIES) => {
  const result = {} as any;
  Object.keys(row).forEach((field) => {
    const [currentEntity, currentField] = field.split('.');
    if (currentEntity === entity) {
      result[currentField] = row[field];
    } else {
      if (!result[currentEntity]) {
        result[currentEntity] = {};
      }
      if (currentField) {
        result[currentEntity][currentField] = row[field];
      }
    }
  });
  return result;
};
export const createContactAutocompleteFilter = (term: string) => {
  const filters:Filters[] = [] as Filters[];
  term.split(',').forEach((subquery: string) => {
    const trimmed = subquery.trim();
    filters.push({
      or: [
        ['contacts.first_name', SEARCH_OPERATORS.like, `%${trimmed}%`],
        ['contacts.last_name', SEARCH_OPERATORS.like, `%${trimmed}%`],
        ['contacts.email', SEARCH_OPERATORS.like, `%${trimmed}%`],
        ['contacts.company_name', SEARCH_OPERATORS.like, `%${trimmed}%`],
      ],
    });
  });
  return filters;
};

export const createContactForUserFilter = (userId: Number) => ({
  [SEARCH_LOGICAL_OPERATORS.and]: [
    ['contacts.user_id', SEARCH_OPERATORS_PRIMITIVE.eq, userId],
  ],
} as Filters);

export const createContactQuickSearchFilter = (payload: ContactQuickSearch) => {
  const { term } = payload;
  const filters:Filters[] = [] as Filters[];
  term.split(',').forEach((subquery: string) => {
    const trimmed = subquery.trim();
    filters.push({
      or: [
        ['contacts.first_name', SEARCH_OPERATORS.like, `%${trimmed}%`],
        ['contacts.last_name', SEARCH_OPERATORS.like, `%${trimmed}%`],
        ['contacts.email', SEARCH_OPERATORS.like, `%${trimmed}%`],
        ['contacts.company_name', SEARCH_OPERATORS.like, `%${trimmed}%`],
      ],
    });
  });
  return {
    and: filters,
  };
};

export const createInvoiceQuickSearchFilter = (invoiceQuickSearch: InvoiceQuickSearch) => {
  const {
    startDate,
    endDate,
    departmentId,
    programId,
    accountId,
    terms,
    invoiceStatus,
    paymentStatus,
  } = invoiceQuickSearch;

  const standardFilters = [] as Filter[];
  if (startDate && endDate) {
    standardFilters.push(
      ['invoices.issued_date', SEARCH_OPERATORS_PRIMITIVE.gt, startDate],
    );
    standardFilters.push(
      ['invoices.issued_date', SEARCH_OPERATORS_PRIMITIVE.lt, endDate],
    );
  }
  if (departmentId && departmentId.length) {
    standardFilters.push(['invoices.department_id', SEARCH_OPERATORS_PRIMITIVE.in, departmentId]);
  }
  if (programId && programId.length) {
    standardFilters.push(['invoices.program_id', SEARCH_OPERATORS_PRIMITIVE.in, programId]);
  }
  if (accountId && accountId.length) {
    standardFilters.push(['invoices.account_id', SEARCH_OPERATORS_PRIMITIVE.in, accountId]);
  }
  if (invoiceStatus && invoiceStatus.length) {
    standardFilters.push(['invoices.state', SEARCH_OPERATORS_PRIMITIVE.in, invoiceStatus]);
  }
  if (paymentStatus && paymentStatus.length) {
    standardFilters.push(['invoices.payment_status', SEARCH_OPERATORS_PRIMITIVE.in, paymentStatus]);
  }

  if (terms) {
    const termsFilters = createInvoiceSearchTermsFilter(terms);
    return {
      [SEARCH_LOGICAL_OPERATORS.and]: [
        { [SEARCH_LOGICAL_OPERATORS.and]: standardFilters },
        ...termsFilters,
      ],
    };
  }
  return { [SEARCH_LOGICAL_OPERATORS.and]: standardFilters };
};

export const createPaymentQuickSearchFilter = (paymentQuickSearch: PaymentQuickSearch) => {
  const {
    startDate,
    endDate,
    departmentId,
    programId,
    accountId,
    terms,
    paymentStatus,
    paymentType,
  } = paymentQuickSearch;

  const standardFilters = [] as Filter[];
  if (startDate && endDate) {
    standardFilters.push(
      ['payments.scheduled_date', SEARCH_OPERATORS_PRIMITIVE.gt, startDate],
    );
    standardFilters.push(
      ['payments.scheduled_date', SEARCH_OPERATORS_PRIMITIVE.lt, endDate],
    );
  }
  if (departmentId && departmentId.length) {
    standardFilters.push(['payments.department_id', SEARCH_OPERATORS_PRIMITIVE.in, departmentId]);
  }
  if (programId && programId.length) {
    standardFilters.push(['payments.program_id', SEARCH_OPERATORS_PRIMITIVE.in, programId]);
  }
  if (accountId && accountId.length) {
    standardFilters.push(['payments.account_id', SEARCH_OPERATORS_PRIMITIVE.in, accountId]);
  }
  if (paymentStatus && paymentStatus.length) {
    standardFilters.push(['payments.payment_status', SEARCH_OPERATORS_PRIMITIVE.in, paymentStatus]);
  }
  if (paymentType && paymentType.length) {
    standardFilters.push(['payments.payment_type', SEARCH_OPERATORS_PRIMITIVE.in, paymentType]);
  }
  if (terms) {
    const termsFilters = createPaymentSearchTermsFilter(terms);
    return {
      [SEARCH_LOGICAL_OPERATORS.and]: [
        { [SEARCH_LOGICAL_OPERATORS.and]: standardFilters },
        ...termsFilters,
      ],
    };
  }

  return { [SEARCH_LOGICAL_OPERATORS.and]: standardFilters };
};

export const getFilterOperator = (value: FilterOperator) => {
  switch (value) {
    case SEARCH_OPERATORS.contain:
      return SEARCH_OPERATORS_PRIMITIVE.like;
    case SEARCH_OPERATORS.not_contain:
      return SEARCH_OPERATORS_PRIMITIVE.not_like;
    case SEARCH_OPERATORS.start_with:
      return SEARCH_OPERATORS_PRIMITIVE.like;
    case SEARCH_OPERATORS.end_with:
      return SEARCH_OPERATORS_PRIMITIVE.like;
    case SEARCH_OPERATORS.is_between:
      return SEARCH_OPERATORS_PRIMITIVE.between;
    case SEARCH_OPERATORS.is_not_between:
      return SEARCH_OPERATORS_PRIMITIVE.not_between;
    case SEARCH_OPERATORS.is_after:
      return SEARCH_OPERATORS_PRIMITIVE.gt;
    case SEARCH_OPERATORS.is_before:
      return SEARCH_OPERATORS_PRIMITIVE.lt;
    case SEARCH_OPERATORS.is_equal:
      return SEARCH_OPERATORS_PRIMITIVE.eq;
    case SEARCH_OPERATORS.is_not_equal:
      return SEARCH_OPERATORS_PRIMITIVE.ne;
    case SEARCH_OPERATORS.is_greater:
      return SEARCH_OPERATORS_PRIMITIVE.gt;
    case SEARCH_OPERATORS.is_less:
      return SEARCH_OPERATORS_PRIMITIVE.lt;
    case SEARCH_OPERATORS.is_empty:
      return SEARCH_OPERATORS_PRIMITIVE.null;
    case SEARCH_OPERATORS.is_not_empty:
      return SEARCH_OPERATORS_PRIMITIVE.not_null;
    case SEARCH_OPERATORS.is_not_define:
      return SEARCH_OPERATORS_PRIMITIVE.null;
    case SEARCH_OPERATORS.is_true:
      return SEARCH_OPERATORS_PRIMITIVE.eq;
    case SEARCH_OPERATORS.is_false:
      return SEARCH_OPERATORS_PRIMITIVE.eq;
    default:
      return value;
  }
};

export const getFilterValue = (operator: FilterOperator, value: any) => {
  switch (operator) {
    case SEARCH_OPERATORS.is_true:
      return 1;
    case SEARCH_OPERATORS.is_false:
      return 0;
    case SEARCH_OPERATORS.is_not_define:
    case SEARCH_OPERATORS.is_empty:
    case SEARCH_OPERATORS.is_not_empty:
      return '';
    case SEARCH_OPERATORS.contain:
      return `%${value}%`;
    case SEARCH_OPERATORS.not_contain:
      return `%${value}%`;
    case SEARCH_OPERATORS.start_with:
      return `${value}%`;
    case SEARCH_OPERATORS.end_with:
      return `%${value}`;
    default:
      return value;
  }
};

export const getExtraValue = (value: SEARCH_OPERATORS) => {
  switch (value) {
    case SEARCH_OPERATORS.start_with:
      return OPERATOR_EXTRA_TYPE.start_with;
    case SEARCH_OPERATORS.end_with:
      return OPERATOR_EXTRA_TYPE.end_with;
    case SEARCH_OPERATORS.is_true:
    case SEARCH_OPERATORS.is_false:
      return OPERATOR_EXTRA_TYPE.boolean;
    default:
      return null;
  }
};

export const getBetweenDate = (filter: Filter) => {
  const newFilter = [...filter];
  newFilter[FILTER_POSITION.operator] = SEARCH_OPERATORS.is_between;
  const value = newFilter[FILTER_POSITION.operands] as Moment;
  const startDate = value.startOf('day');
  const endDate = value.endOf('day');
  newFilter[FILTER_POSITION.operands] = [startDate, endDate];
  return newFilter;
};

export const getOperatorAndFilters = (filters: Filters) => {
  switch (true) {
    case !!filters[SEARCH_LOGICAL_OPERATORS.and]:
      return [SEARCH_LOGICAL_OPERATORS.and, filters[SEARCH_LOGICAL_OPERATORS.and]];
    case !!filters[SEARCH_LOGICAL_OPERATORS.or]:
      return [SEARCH_LOGICAL_OPERATORS.or, filters[SEARCH_LOGICAL_OPERATORS.or]];
    default:
      return [SEARCH_LOGICAL_OPERATORS.or, [['', '', '']] as Filter[]];
  }
};

const getFilter = (filter: Filter) => {
  const value = filter[FILTER_POSITION.operands];
  const operator = filter[FILTER_POSITION.operator];
  const newFilter = [...filter] as Filter;
  if (
    operator === SEARCH_OPERATORS.is_between &&
    moment.isMoment(value[0]) &&
    moment.isMoment(value[1])
  ) {
    const startDate = moment(value[0]).startOf('day');
    const endDate = moment(value[1]).endOf('day');
    newFilter[FILTER_POSITION.operands] = [startDate, endDate];
    return newFilter;
  }
  if (moment.isMoment(value)) {
    switch (filter[FILTER_POSITION.operator]) {
      case SEARCH_OPERATORS.is_before: {
        newFilter[FILTER_POSITION.operands] = moment(value).startOf('day');
        return newFilter;
      }
      case SEARCH_OPERATORS.is_after: {
        newFilter[FILTER_POSITION.operands] = moment(value).endOf('day');
        return newFilter;
      }
      case SEARCH_OPERATORS.is_not_define: {
        return newFilter;
      }
      default: {
        newFilter[FILTER_POSITION.operator] = SEARCH_OPERATORS.is_between;
        const startDate = moment(value).startOf('day');
        const endDate = moment(value).endOf('day');
        newFilter[FILTER_POSITION.operands] = [startDate, endDate];
        return newFilter;
      }
    }
  }
  return newFilter;
};

export const denormalizeFilters = (filters:Filter[]) => {
  const result = [] as Filter[];
  filters.forEach((filter: Filter) => {
    const current = getFilter(filter);
    const operator = current[FILTER_POSITION.operator] as FilterOperator;
    const value = current[FILTER_POSITION.operands];
    current[FILTER_POSITION.operands] = getFilterValue(operator, value);
    current[FILTER_POSITION.operator] = getFilterOperator(operator);
    result.push(current);
  });
  return result;
};

export const kebabToSnake = (value: string) => value.replace('-', '_');
