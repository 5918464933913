const creditcard = {
  field_required: 'This field  is required',
  validation_errors: 'Validation error(s). Please review your data.',
  exact_digit_2: 'Must be exactly 2 digits',
  exact_digit_3: 'Must be exactly 3 digits',
  exact_digit_5: 'Must be exactly 5 digits',
  exact_digit_12: 'Must be 15 or 16 digits',
  between_5_20_digit: 'Must be between 5 and 20 digits',
  only_digit: 'Must be only digits',
  year_must_be_future: 'Year must be future',
};

export default creditcard;
