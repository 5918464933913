import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  alignItems?: string;
  children: any,
  height?: string;
  style?: CSSProperties;
}

const Row = (props: Props) => {
  const {
    alignItems,
    children,
    height,
    style,
  } = props;

  const styles: CSSProperties = {
    alignItems: alignItems ?? 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    height: height ?? 'auto',
    padding: '4px 0px',
    width: '100%',
    ...style,
  };
  return (
    <div style={styles}>
      {children}
    </div>
  );
};

export default Row;
