import { TextField, Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import AddIcon from '@material-ui/icons/Add';

interface Props {
  onAdd: () => void,
  onChange: (term: string) => void;
  testId?: string,
  title:string,
}

const ItemSearchBox = (props: Props) => {
  const {
    onAdd, onChange, testId, title,
  } = props;
  return (
    <div style={{
      height: '6vh',
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
    }}>
      <Tooltip
        title={title}
        placement="top-start">
        <span>
          <ActionButton
            category={MENU_BUTTON_CATEGORY.action}
            data-testid={testId}
            onClick={() => onAdd()}>
            <AddIcon />
          </ActionButton>
        </span>
      </Tooltip>
      <TextField
        margin="dense"
        variant="outlined"
        label="Filter Groups"
        onChange={(e) => onChange(e.target.value)} />
    </div>
  );
};

export default ItemSearchBox;
//
