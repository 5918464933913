import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { t } from 'src/lib/language';
import { closeModal, openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { MODALS_SIZE } from 'src/models/modal';
import { MouseEvent, useEffect, useState } from 'react';
import {
  InvoicePaymentItem,
  PAYMENT_STATUS,
  PAYMENT_STATUS_COLOR,
  PAYMENT_TYPE,
  PaymentModel,
} from 'src/models/PaymentModel';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { setError } from 'src/redux/actions/errorsActions';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import PaymentValidator from 'src/validations/PaymentValidator';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import {
  FormControl, FormControlLabel, Radio, RadioGroup, Tooltip,
} from '@material-ui/core';
import { INVOICE_FIELDS } from 'src/constants/Invoices';
import ContactSelector from 'src/components/Control/ContactSelector';
import { AUTOCOMPLETE_ID } from 'src/constants/Autocomplete';
import PaymentAccountSelector from 'src/components/Control/PaymentAccountSelector';
import OrganisationBankAccountSelector
  from 'src/components/Control/OrganisationBankAccountSelector';
import { getPaymentAction, putPaymentAction } from 'src/redux/actions/paymentActions';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import TextLink from 'src/components/UI/TextLink';
import FormSingleSelect from 'src/components/Control/FormControls/FormSingleSelect';
import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Subtitle from 'src/components/UI/Subtitle';
import MetadataSelector, { MetadataType } from 'src/components/Control/MetadataSelector';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import SaveIcon from '@material-ui/icons/Save';
import { InvoiceModel } from 'src/models/InvoiceModel';
import ManageAccountsButton from 'src/components/DatagridToolbar/buttons/ManageAccountsButton';
import ManageDepartmentsButton
  from 'src/components/DatagridToolbar/buttons/ManageDepartmentsButton';
import ManageProgramsButton from 'src/components/DatagridToolbar/buttons/ManageProgramsButton';
import ManageProductsButton from 'src/components/DatagridToolbar/buttons/ManageProductsButton';
import DownloadPaymentButton from 'src/components/DatagridToolbar/buttons/DownloadPaymentButton';
import CancelPaymentButton from 'src/components/DatagridToolbar/buttons/CancelPaymentButton';
import RefundPaymentButton from 'src/components/DatagridToolbar/buttons/RefundPaymentButton';
import GatewaySelector from 'src/components/Control/GatewaySelector';
import { ProcessingType } from 'src/models/GatewayModel';
import { isEmpty, keyBy } from 'lodash';
import AddressSearch from 'src/components/Control/AddressSearch';
import { AddressModel } from 'src/models/AddressModel';
import CountriesSelector from 'src/components/Control/CountriesSelector';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import { BankAccountModel } from 'src/models/BankAccountModel';
import { getContactCreditCardAction } from 'src/redux/actions/creditCardActions';
import { CreditCardItemModel } from 'src/models/CreditCardListModel ';
import { getContactBankAccountAction } from 'src/redux/actions/bankAccountActions';
import Header from 'src/components/UI/Header';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';
import PaginationUI from 'src/components/UI/PaginationUI';
import Form from 'src/components/UI/Form';
import Col3 from 'src/components/UI/Col3';
import EditIcon from 'src/components/UI/EditIcon';
import Col2 from 'src/components/UI/Col2';
import Scroller from 'src/components/UI/Scroller';
import { setSelections } from 'src/redux/actions/selectionActions';

const EditPaymentForm = () => {
  const paymentTypeStyle = {
    margin: '0px',
    marginLeft: '-8px',
    marginTop: '-8px',
  };

  const dispatch = useAppDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [viewContactState, setViewContactState] = useState(true);

  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const countries = useSelector(
    (state: Store) => keyBy(state.data.countries ?? [], 'id'),
  );
  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.payments] || [],
  );
  const payment = useSelector(
    (state: Store) => {
      if (!!state.payments[selections[currentIndex] as number]) {
        const currentPayment = state.payments[selections[currentIndex as number]];
        return currentPayment as PaymentModel;
      }
      return {} as PaymentModel;
    },
  );
  const OrganisationBankAccounts = useSelector((state: Store) => state.organisationBankAccounts);

  const creditCards = useSelector(
    (state: Store) => {
      if (!payment.contact_id) return [] as CreditCardItemModel[];
      if (!!state.creditcards) {
        return state.creditcards;
      }
      return [] as CreditCardItemModel[];
    },
  );

  const bankAccounts = useSelector(
    (state: Store) => {
      if (!payment.contact_id) return [] as BankAccountModel[];
      if (!!state.contactBankAccounts) {
        return state.contactBankAccounts[payment.contact_id] as BankAccountModel[];
      }
      return [] as BankAccountModel[];
    },
  );

  const organisationId = useSelector((state: Store) => state.currentOrganisation.id);
  const getStatus = useSelector((state: Store) => state.formStatus[FORM.get_payment]);
  const putStatus = useSelector((state: Store) => state.formStatus[FORM.put_payment]);
  const processing = () => putStatus === FORM_STATUS.processing
    || getStatus === FORM_STATUS.processing;

  const loaded = useSelector(
    (state: Store) => !!state.payments[selections[currentIndex] as number],
  );

  const [paymentState, setPaymentState] = useState({
    ...payment,
  });

  const setAddressFromGoogle = (address: AddressModel) => {
    setPaymentState({
      ...payment,
      billing_address: address.address,
      billing_city: address.city,
      billing_state: address.state,
      billing_country_id: address.country_id,
      billing_zip_code: (!!address.zip_code) ? address.zip_code.replaceAll(' ', '') : '',
    });
  };

  const openAddCreditCardModal = () => {
    dispatch(openModal({
      modal: MODALS.addCreditCard,
      payload: {
        contact_id: paymentState.contact_id,
      },
    }));
  };

  const openAddBankAccountModal = () => {
    dispatch(openModal({
      modal: MODALS.addBankAccount,
      payload: {
        contact_id: paymentState.contact_id,
      },
    }));
  };

  const openInvoice = (id: number) => {
    dispatch(setSelections({
      entity: ENTITIES.invoices,
      selections: [id],
    }));
    dispatch(openModal({ modal: MODALS.editInvoice }));
  };

  useEffect(() => {
    dispatch(setError({ [FORM.edit_payment]: {} }));
    selections.forEach(
      (paymentId: number) => dispatch(getPaymentAction(paymentId as number)),
    );
  }, []);

  useEffect(() => {
    dispatch(setError({ [FORM.edit_payment]: {} }));
    if (selections[currentIndex]) {
      dispatch(getPaymentAction(selections[currentIndex] as number));
    }
  }, [currentIndex]);

  useEffect(() => {
    if (loaded) {
      setPaymentState({
        ...payment,
        scheduled_date: payment.scheduled_date ?? null,
        paid_date: payment.paid_date ?? null,
      });
      if (payment.contact_id) {
        if (payment.payment_type === PAYMENT_TYPE.cc) {
          dispatch(getContactCreditCardAction(payment.contact_id));
        }
        if (payment.payment_type === PAYMENT_TYPE.eft) {
          dispatch(getContactBankAccountAction(payment.contact_id));
        }
      }
    }
  }, [payment]);

  const getCreditCardInfo = (id: number) => {
    if (!id) return 'N/A';
    const result = creditCards[id];
    if (!result) return 'N/A';
    return result.masked_card_number;
  };

  const getBankAccountInfo = (id: number) => {
    if (!id || !bankAccounts) return 'N/A';
    const result = bankAccounts.find((bankAccount) => bankAccount.id === id);
    if (!result) return 'N/A';
    return result.account.slice(-4);
  };

  const onAmountChange = (value: Value, field: string) => {
    if (!value) {
      setPaymentState({
        ...paymentState,
        amount: value as string,
      });
      return;
    }
    const stringValue = value as string || '';
    if (!stringValue.match(/^[0-9.]+$/)) return;
    const splittedString = stringValue.split('.');
    if (splittedString.length > 2) return;
    if (splittedString.length === 2) {
      if (splittedString[1].length > 2) return;
    }
    setPaymentState({
      ...paymentState,
      [field]: value as string,
    });
  };

  const onFieldChange = (value: Value, field: string) => {
    if (field === 'payment_status' && value === PAYMENT_STATUS.scheduled) {
      setPaymentState({
        ...paymentState,
        paid_date: null,
        [field]: value,
      });
      return;
    }

    if (field === 'payment_status' && value === PAYMENT_STATUS.paid) {
      if (paymentState.payment_status === PAYMENT_STATUS.scheduled) {
        setPaymentState({
          ...paymentState,
          scheduled_date: null,
          [field]: value,
        });
        return;
      }
    }

    setPaymentState({
      ...paymentState,
      [field]: value,
    });
  };

  const onDateChange = (value: any, field: string) => {
    setPaymentState({
      ...paymentState,
      [field]: value,
    });
  };

  const handlePageChange = (newPage:number) => {
    setPage(newPage);
    setCurrentIndex(newPage - 1);
  };

  const displayPaymentStatus = () => {
    const color = PAYMENT_STATUS_COLOR[payment.payment_status ?? PAYMENT_STATUS.paid];
    return (
      <span style={{ color }}>{ payment.payment_status }</span>
    );
  };

  const displayGatewaySelector = () => {
    switch (paymentState.payment_type) {
      case PAYMENT_TYPE.cc:
        return (
          <GatewaySelector
            form={FORM.make_payment}
            name="payment_gateway_id"
            onChange={(value) => onFieldChange(value, 'payment_gateway_id')}
            type={ProcessingType.cc}
            value={paymentState.payment_gateway_id}
            noMarginTop />
        );
      case PAYMENT_TYPE.eft:
        return (
          <GatewaySelector
            form={FORM.make_payment}
            name="payment_gateway_id"
            onChange={(value) => onFieldChange(value, 'payment_gateway_id')}
            type={ProcessingType.eft}
            value={paymentState.payment_gateway_id}
            noMarginTop />
        );
      default:
        return (<></>);
    }
  };

  const getName = (state: PaymentModel) => {
    if (!!state.contact_company_name) {
      return state.contact_company_name;
    }
    return `${state.contact_last_name} ${state.contact_first_name}`;
  };

  const displayContactFullName = () => {
    if (isEmpty(payment)) return <></>;
    return (
      <Row style={{ marginTop: '16px' }}>
        <h3>
          {getName(paymentState)}&nbsp;
          <EditIcon onClick={() => setViewContactState(!viewContactState)} />
        </h3>
      </Row>
    );
  };

  const displayPaymentDetails = () => {
    if (isEmpty(paymentState)) {
      return '';
    }
    return (
      <Row height="auto">
        <Col2>
          <b>{ t(lang, 'forms.invoices.billing_address') }:</b>
          <div>{ paymentState.billing_address } ({ paymentState.billing_suite })</div>
          <div>
            { paymentState.billing_city },&nbsp;
            { paymentState.billing_state }
          </div>
          <div>{ paymentState.billing_zip_code }</div>
          <div>
            { `${!!countries[paymentState.billing_country_id ?? 0] && countries[paymentState.billing_country_id ?? 0].name_en}` }
          </div>
        </Col2>
      </Row>
    );
  };

  const displayPaymentInfo = () => {
    if (paymentState.payment_type === PAYMENT_TYPE.cc) {
      return (
        <Row style={{ marginTop: '8px' }}>
          <span style={{ fontWeight: 'bold', marginRight: '8px' }}>
            Credit Card Used:
          </span>
          {getCreditCardInfo(paymentState.payment_info_id as number)}
        </Row>
      );
    }
    if (paymentState.payment_type === PAYMENT_TYPE.eft) {
      return (
        <Row style={{ marginTop: '8px' }}>
          <span style={{ fontWeight: 'bold', marginRight: '8px' }}>
            Bank Account Used:
          </span>
          ********{getBankAccountInfo(paymentState.payment_info_id as number)}
        </Row>
      );
    }
    return (<></>);
  };

  const displayInvoices = () => {
    if (!payment.invoices) return <></>;
    return payment.invoices.map(
      (invoice: any) => (
        <TextLink onClick={() => openInvoice(invoice.id)}>
          {invoice.invoice_number}
        </TextLink>
      ),
    );
  };

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.editPayment }));
  };

  const isOfflinePaymentType = () => [
    PAYMENT_TYPE.cash,
    PAYMENT_TYPE.check,
    PAYMENT_TYPE.inkind,
    PAYMENT_TYPE.terminal,
    PAYMENT_TYPE.wired,
    PAYMENT_TYPE.interac,
    PAYMENT_TYPE.userbalance,
  ].includes(paymentState.payment_type);

  const isOnlinePaymentType = () => [
    PAYMENT_TYPE.cc,
    PAYMENT_TYPE.eft,
  ].includes(paymentState.payment_type);

  const enableAllPaymentType = () => (payment.payment_status && [
    PAYMENT_STATUS.declined,
    PAYMENT_STATUS.scheduled,
    PAYMENT_STATUS.error,
    PAYMENT_STATUS.holding,
  ].includes(payment.payment_status));

  const getPaymentTypesLabels = () => (
    <>
      <Row height="auto">
        <Col3>
          <FormControlLabel
            style={paymentTypeStyle}
            value={PAYMENT_TYPE.cash}
            control={<Radio />}
            label={PAYMENT_TYPE.cash}
            disabled={isOnlinePaymentType() && !enableAllPaymentType()}
            key="payment-type-0" />
        </Col3>
        <Col3>
          <FormControlLabel
            style={paymentTypeStyle}
            value={PAYMENT_TYPE.check}
            control={<Radio />}
            disabled={isOnlinePaymentType() && !enableAllPaymentType()}
            label={PAYMENT_TYPE.check}
            key="payment-type-1" />
        </Col3>
        <Col3>
          <FormControlLabel
            style={paymentTypeStyle}
            value={PAYMENT_TYPE.inkind}
            control={<Radio />}
            disabled={isOnlinePaymentType() && !enableAllPaymentType()}
            label={PAYMENT_TYPE.inkind}
            key="payment-type-2" />
        </Col3>
      </Row>
      <Row height="auto">
        <Col3>
          <FormControlLabel
            style={paymentTypeStyle}
            value={PAYMENT_TYPE.interac}
            control={<Radio />}
            disabled={isOnlinePaymentType() && !enableAllPaymentType()}
            label={PAYMENT_TYPE.interac}
            key="payment-type-0" />
        </Col3>
        <Col3>
          <FormControlLabel
            style={paymentTypeStyle}
            value={PAYMENT_TYPE.terminal}
            control={<Radio />}
            disabled={isOnlinePaymentType() && !enableAllPaymentType()}
            label={PAYMENT_TYPE.terminal}
            key="payment-type-1" />
        </Col3>
        <Col3>
          <FormControlLabel
            style={paymentTypeStyle}
            value={PAYMENT_TYPE.userbalance}
            control={<Radio />}
            disabled={isOnlinePaymentType() && !enableAllPaymentType()}
            label={PAYMENT_TYPE.userbalance}
            key="payment-type-2" />
        </Col3>
      </Row>
      <Row height="auto">
        <Col3>
          <FormControlLabel
            style={paymentTypeStyle}
            value={PAYMENT_TYPE.wired}
            control={<Radio />}
            disabled={isOnlinePaymentType() && !enableAllPaymentType()}
            label={PAYMENT_TYPE.wired}
            key="payment-type-2" />
        </Col3>
        <Col3>
          <FormControlLabel
            style={paymentTypeStyle}
            value={PAYMENT_TYPE.cc}
            control={<Radio />}
            disabled={isOfflinePaymentType() && !enableAllPaymentType()}
            label={PAYMENT_TYPE.cc}
            key="payment-type-2" />
        </Col3>
        <Col3>
          <FormControlLabel
            style={paymentTypeStyle}
            value={PAYMENT_TYPE.eft}
            control={<Radio />}
            disabled={isOfflinePaymentType() && !enableAllPaymentType()}
            label={PAYMENT_TYPE.eft}
            key="payment-type-2" />
        </Col3>
      </Row>
    </>
  );

  const getPaymentStatusList = () => {
    const paymentStatus = payment.payment_status || '';
    if (!paymentStatus) {
      return [];
    }
    // PAID
    if (paymentStatus === PAYMENT_STATUS.paid) {
      return [paymentStatus];
    }
    // PAID, REFUNDED, REFUNDING, CANCELLED, CANCELLATION
    if ([
      PAYMENT_STATUS.paid,
      PAYMENT_STATUS.refunded,
      PAYMENT_STATUS.refunding,
      PAYMENT_STATUS.cancelled,
      PAYMENT_STATUS.cancellation,
    ].includes(paymentStatus)) {
      return [paymentStatus];
    }

    if (isOnlinePaymentType()) {
      // PENDING
      if (paymentStatus === PAYMENT_STATUS.pending) {
        return [paymentStatus];
      }
      // SCHEDULED, HOLDING
      if ([
        PAYMENT_STATUS.scheduled,
        PAYMENT_STATUS.holding,
      ].includes(paymentStatus)) {
        return [
          PAYMENT_STATUS.scheduled,
          PAYMENT_STATUS.holding,
        ];
      }
      // DECLINED, ERROR
      if ([
        PAYMENT_STATUS.declined,
        PAYMENT_STATUS.error,
      ].includes(paymentStatus)) {
        return [
          paymentStatus,
          PAYMENT_STATUS.holding,
          PAYMENT_STATUS.scheduled,
        ];
      }
    }
    if (isOfflinePaymentType()) {
      // PENDING, SCHEDULED, DECLINED, ERROR, HOLDING
      if ([
        PAYMENT_STATUS.pending,
        PAYMENT_STATUS.scheduled,
        PAYMENT_STATUS.declined,
        PAYMENT_STATUS.error,
        PAYMENT_STATUS.holding,
      ].includes(paymentStatus)) {
        return [
          PAYMENT_STATUS.pending,
          PAYMENT_STATUS.scheduled,
          PAYMENT_STATUS.declined,
          PAYMENT_STATUS.error,
          PAYMENT_STATUS.holding,
          PAYMENT_STATUS.paid,
        ];
      }
    }
    return [paymentStatus];
  };

  const getPaymentStatusItems = () => getPaymentStatusList()
    .map((status) => (
      { display: status, id: status }
    ));

  const getInvoicesAmount = () => {
    let remainingBalance = Number(paymentState.amount) ?? 0;
    const invoiceItems:InvoicePaymentItem[] = [];
    const invoices = payment.invoices ?? [];

    if (invoices.length === 1) {
      invoiceItems.push({
        invoice_id: invoices[0].id,
        amount: remainingBalance,
      } as InvoicePaymentItem);
      return invoiceItems;
    }

    return invoices.forEach((invoice: InvoiceModel) => {
      if (remainingBalance) {
        invoiceItems.push({
          invoice_id: invoice.id,
          amount: ((invoice.balance ?? 0) < remainingBalance)
            ? invoice.balance
            : remainingBalance,
        } as InvoicePaymentItem);

        const balance = Number(invoice.balance) ?? 0;
        remainingBalance = (balance < remainingBalance)
          ? remainingBalance - balance
          : 0;
      }
    });
  };

  const bankAccountExists = (id: number) => {
    const accounts = OrganisationBankAccounts[organisationId] ?? [];
    const result = accounts.filter((account: any) => account.id === id);
    return result.length > 0;
  };

  const canUpdateField = (field: string) => {
    if (!payment.payment_status) return false;

    if (isOnlinePaymentType()) {
      // PAID
      if (payment.payment_status === PAYMENT_STATUS.paid) {
        return [
          'processed_date',
          'description',
          'department_id',
          'program_id',
          'account_id',
          'deductible',
          'issue_tax_receipt',
        ].includes(field);
      }
      // REFUND, REFUNDING
      if (
        [
          PAYMENT_STATUS.refunding,
          PAYMENT_STATUS.refunded,
        ].includes(payment.payment_status)
      ) {
        return [
          'processed_date',
          'description',
          'department_id',
          'program_id',
          'account_id',
        ].includes(field);
      }
      // PENDING
      if (payment.payment_status === PAYMENT_STATUS.pending) {
        return [
          'description',
          'department_id',
          'program_id',
          'account_id',
          'deductible',
          'issue_tax_receipt',
          'seller_id',
        ].includes(field);
      }

      // SCHEDULED, DECLINED, ERROR, HOLDING
      if (
        [
          PAYMENT_STATUS.scheduled,
          PAYMENT_STATUS.declined,
          PAYMENT_STATUS.error,
          PAYMENT_STATUS.holding,
        ].includes(payment.payment_status)
      ) {
        return [
          'description',
          'department_id',
          'program_id',
          'account_id',
          'payment_type',
          'scheduled_date',
          'amount',
          'deductible',
          'payment_type',
          'payment_status',
          'issue_tax_receipt',
        ].includes(field);
      }
      return false;
    }

    if (isOfflinePaymentType()) {
      // PAID
      if (payment.payment_status === PAYMENT_STATUS.paid) {
        return [
          'paid_date',
          'bank_account_id',
          'processed_date',
          'description',
          'department_id',
          'program_id',
          'account_id',
          'deductible',
          'issue_tax_receipt',
        ].includes(field);
      }

      // PENDING
      if (payment.payment_status === PAYMENT_STATUS.pending) {
        return [
          'processed_date',
          'scheduled_date',
          'paid_date',
          'description',
          'department_id',
          'program_id',
          'account_id',
          'deductible',
          'issue_tax_receipt',
          'payment_status',
          'payment_type',
        ].includes(field);
      }

      // SCHEDULED, DECLINED, ERROR, HOLDING
      if (
        [
          PAYMENT_STATUS.scheduled,
          PAYMENT_STATUS.declined,
          PAYMENT_STATUS.error,
          PAYMENT_STATUS.holding,
        ].includes(payment.payment_status)
      ) {
        return [
          'amount',
          'deductible',
          'payment_status',
          'description',
          'department_id',
          'program_id',
          'account_id',
          'payment_type',
          'scheduled_date',
          'processed_date',
          'paid_date',
        ].includes(field);
      }
      // CANCELLATION, CANCEL
      if (
        [
          PAYMENT_STATUS.cancellation,
          PAYMENT_STATUS.cancelled,
        ].includes(payment.payment_status)
      ) {
        return [
          'description',
          'department_id',
          'program_id',
          'account_id',
          'department_id',
          'processed_date',
          'bank_account_id',
        ].includes(field);
      }
    }
    return undefined;
  };

  const statusPaymentDone = () => paymentState.payment_status &&
    [
      PAYMENT_STATUS.scheduled,
      PAYMENT_STATUS.holding,
      PAYMENT_STATUS.declined,
    ].includes(paymentState.payment_status);

  const getTransformedData = () => {
    const data = { ...paymentState };

    data.paid_date = data.paid_date ? data.paid_date : null;

    if (data.bank_account_id && !bankAccountExists(data.bank_account_id)) {
      delete data.bank_account_id;
    }

    if (isOnlinePaymentType() && paymentState.payment_status !== PAYMENT_STATUS.scheduled) {
      data.scheduled_date = null;
    }

    if (isOfflinePaymentType()) {
      // delete data.paid_date;
      if (
        payment.payment_status === PAYMENT_STATUS.scheduled &&
        data.payment_status === PAYMENT_STATUS.paid
      ) {
        data.scheduled_date = null;
        data.processed_date = moment();
      }
    }
    return data;
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setError({ [FORM.edit_payment]: {} }));
    const data = getTransformedData();

    try {
      PaymentValidator(lang).validateSync({ ...data }, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: t(lang, 'forms.contact.validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.edit_payment]: errorBag }));
      return false;
    }

    const payload = {
      ...data,
      invoices: getInvoicesAmount(),
    };
    dispatch(putPaymentAction(Number(payload.id), payload));
    return true;
  };

  const CloseButton = () => (
    <Button
      style={{ marginLeft: 'auto' }}
      variant="contained"
      color="primary"
      size="small"
      onClick={handleClose}
      disabled={processing()}>
      { t(lang, 'misc.close') }
    </Button>
  );

  const SaveButton = () => (
    <Tooltip
      title={t(lang, 'menus.edit_payment')}
      placement="top-start">
      <span>
        <ActionButton
          disabled={processing()}
          category={MENU_BUTTON_CATEGORY.action}
          onClick={handleSubmit}>
          <SaveIcon />
        </ActionButton>
      </span>
    </Tooltip>
  );

  return (
    <>
      <Header height="86px">
        <Row>
          <Title>
            {t(lang, 'forms.payments.edit_payment')}&nbsp;#{paymentState.id}
          </Title>
          <CloseButton />
        </Row>
        <Row>
          <SaveButton />
          <ManageAccountsButton />
          <ManageDepartmentsButton />
          <ManageProgramsButton />
          <ManageProductsButton />
          <DownloadPaymentButton />
          <CancelPaymentButton />
          <RefundPaymentButton paymentId={selections[currentIndex]} />
        </Row>
      </Header>
      <div><b>Status: </b>{ displayPaymentStatus() }</div>
      <PaginationUI
        count={selections.length}
        page={page}
        onChange={handlePageChange} />
      <Scroller>
        <Subtitle noMargin>Invoices</Subtitle>
        {displayInvoices()}
        <br />
        <Form maxWidth={MODALS_SIZE.large}>
          <Subtitle noMargin>Title</Subtitle>
          <Row height="auto">
            <FormTextField
              form={FORM.make_payment}
              label={t(lang, 'forms.invoices.title')}
              name="title"
              onChange={onFieldChange}
              required
              value={paymentState.title} />
          </Row>
          <Subtitle>Dates</Subtitle>
          <Row height="auto">
            <Col3>
              <FormDatePicker
                disabled={!canUpdateField('paid_date')}
                form={FORM.edit_payment}
                label={t(lang, 'forms.payments.paid_date')}
                name="paid_date"
                onChange={
                (date: MaterialUiPickersDate) => onDateChange(date, 'paid_date')
              }
                value={paymentState.paid_date} />
            </Col3>
            { (paymentState.payment_status === PAYMENT_STATUS.scheduled) && (
            <Col3>
              <FormDatePicker
                disabled={!canUpdateField('scheduled_date')}
                form={FORM.edit_payment}
                label={t(lang, 'forms.payments.scheduled_date')}
                name="scheduled_date"
                onChange={
                (date: MaterialUiPickersDate) => onDateChange(date, 'scheduled_date')
              }
                value={paymentState.scheduled_date} />
            </Col3>
            )}
            <Col3>
              <FormDatePicker
                disabled={!canUpdateField('processed_date') && statusPaymentDone()}
                form={FORM.edit_payment}
                label={t(lang, 'forms.payments.processed_date')}
                name="processed_date"
                onChange={
                (date: MaterialUiPickersDate) => onDateChange(date, 'processed_date')
              }
                value={paymentState.processed_date} />
            </Col3>
          </Row>
          <Subtitle style={{ marginBottom: '24px' }}>Contacts</Subtitle>
          <Row height="auto">
            <ContactSelector
              style={{ width: '100%' }}
              disabled={canUpdateField('contact_id')}
              autocompleteId={AUTOCOMPLETE_ID.add_payment_contact}
              onChange={(id:number) => onFieldChange(id, INVOICE_FIELDS.contact_id)}
              label={t(lang, 'forms.payments.payer')}
              form={FORM.edit_payment}
              name={INVOICE_FIELDS.contact_id}
              contactId={paymentState.contact_id} />
          </Row>
          <Row height="auto">
            <ContactSelector
              style={{ width: '100%' }}
              disabled={canUpdateField('seller_id')}
              autocompleteId={AUTOCOMPLETE_ID.add_payment_contact}
              onChange={(id: number) => onFieldChange(id, INVOICE_FIELDS.seller_id)}
              label={t(lang, 'forms.payments.seller')}
              form={FORM.edit_payment}
              name={INVOICE_FIELDS.seller_id}
              contactId={paymentState.seller_id} />
          </Row>
          {displayContactFullName()}
          { (viewContactState) && displayPaymentDetails() }
          { (!viewContactState) && (
          <div>
            <Row height="auto" style={{ marginTop: '16px' }}>
              <Col2>
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'fields.invoices.contact_first_name')}
                  name={INVOICE_FIELDS.contact_first_name}
                  onChange={onFieldChange}
                  value={paymentState.contact_first_name || ''} />
              </Col2>
              <Col2>
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'forms.contact.last_name')}
                  name={INVOICE_FIELDS.contact_last_name}
                  onChange={onFieldChange}
                  value={paymentState.contact_last_name || ''} />
              </Col2>
            </Row>
            <Row height="auto" style={{ marginTop: '16px' }}>
              <Col2>
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'forms.contact.company_name')}
                  name={INVOICE_FIELDS.contact_company_name}
                  onChange={onFieldChange}
                  value={paymentState.contact_company_name || ''} />
              </Col2>
            </Row>
            <Row height="auto" style={{ marginTop: '16px' }}>
              <Col2>
                <Subtitle noMargin style={{ marginBottom: '8px' }}>
                  { t(lang, 'forms.contact.header_billing_address') }
                </Subtitle>
                <hr />
                <AddressSearch
                  onChange={onFieldChange}
                  onAddressChange={
                    (addresse: AddressModel) => {
                      setAddressFromGoogle(addresse);
                    }
                  }
                  value={{ description: paymentState.billing_address || '' }}
                  name="billing_address"
                  form={FORM.edit_invoice} />
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'forms.contact.suite')}
                  name={INVOICE_FIELDS.billing_suite}
                  onChange={onFieldChange}
                  value={paymentState.billing_suite || ''} />
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'forms.contact.city')}
                  name={INVOICE_FIELDS.billing_city}
                  onChange={onFieldChange}
                  value={paymentState.billing_city || ''} />
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'forms.contact.state')}
                  name={INVOICE_FIELDS.billing_state}
                  onChange={onFieldChange}
                  value={paymentState.billing_state || ''} />
                <CountriesSelector
                  form={FORM.edit_invoice}
                  onChange={(id) => onFieldChange(id, 'billing_country_id')}
                  value={paymentState.billing_country_id || undefined}
                  name="billing_country_id" />
                <FormTextField
                  form={FORM.edit_invoice}
                  label={t(lang, 'forms.contact.zip_code')}
                  name={INVOICE_FIELDS.billing_zip_code}
                  onChange={onFieldChange}
                  value={paymentState.billing_zip_code || ''} />
              </Col2>
            </Row>
          </div>
          )}
          <Subtitle style={{ marginBottom: '16px' }}>{t(lang, 'forms.payments.payment_type')}</Subtitle>
          <Row height="auto">
            <FormControl
              component="fieldset"
              disabled={!canUpdateField('payment_type')}>
              <RadioGroup
                row
                aria-label={t(lang, 'forms.payments.payment_type')}
                name="payment_type"
                value={paymentState.payment_type}
                onChange={(e) => onFieldChange(e.target.value, 'payment_type')}>
                {getPaymentTypesLabels()}
              </RadioGroup>
            </FormControl>
          </Row>
          {displayPaymentInfo()}
          <Row height="auto" style={{ marginTop: '16px' }}>
            <Col2>
              { canUpdateField('payment_type') && (
              <>
                <PaymentAccountSelector
                  style={{ marginTop: '4px' }}
                  value={paymentState.payment_info_id || undefined}
                  type={paymentState.payment_type}
                  onChange={(id:number) => onFieldChange(id, 'payment_info_id')}
                  form={FORM.edit_payment}
                  name="payment_info_id"
                  contactId={paymentState.contact_id as number ?? 1}
                  noMarginTop />
                {(paymentState.payment_type === PAYMENT_TYPE.cc) && (
                <TextLink onClick={() => openAddCreditCardModal()}>{t(lang, 'forms.payments.add_credit_card')}</TextLink>
                )}
                {(paymentState.payment_type === PAYMENT_TYPE.eft) && (
                <TextLink onClick={() => openAddBankAccountModal()}>{t(lang, 'forms.payments.add_bank_account')}</TextLink>
                )}
              </>
              )}
            </Col2>
          </Row>
          <Subtitle style={{ marginBottom: '8px' }}>{t(lang, 'forms.payments.amount')}</Subtitle>
          <Row height="auto">
            <Col3>
              <FormTextField
                form={FORM.edit_payment}
                label={t(lang, 'forms.payments.amount')}
                name="amount"
                disabled={!canUpdateField('amount')}
                onChange={onAmountChange ?? '0.00'}
                required
                value={paymentState.amount} />
            </Col3>
            <Col3>
              <FormTextField
                form={FORM.edit_payment}
                label={`${t(lang, 'forms.payments.deductible')} (amount)`}
                name="deductible"
                onChange={onAmountChange}
                value={paymentState.deductible ?? '0.00'}
                required
                disabled />
            </Col3>
            <Col3>
              <FormSingleSelect
                style={{ minWidth: '100%' }}
                form={FORM.edit_payment}
                name="paymentStatus"
                label={t(lang, 'forms.invoices.payment_status')}
                onChange={(status: string) => onFieldChange(status, 'payment_status')}
                value={paymentState.payment_status}
                items={getPaymentStatusItems()}
                noMarginTop
                disabled={!canUpdateField('payment_status')} />
            </Col3>
          </Row>
          <Row height="auto">
            <FormControl
              component="fieldset"
              style={{ marginTop: '8px' }}>
              <FormCheckbox
                name="issue_tax_receipt"
                label={t(lang, 'forms.payments.issue_tax_receipt')}
                checked={!!paymentState.issue_tax_receipt}
                onChange={onFieldChange} />
            </FormControl>
          </Row>
          <Subtitle style={{ marginBottom: '8px' }}>{t(lang, 'forms.invoices.accounts')}</Subtitle>
          <Row height="auto">
            <Col2>
              <OrganisationBankAccountSelector
                style={{ minWidth: '100%' }}
                value={paymentState.bank_account_id || undefined}
                onChange={(id:number) => onFieldChange(id, 'bank_account_id')}
                form={FORM.edit_payment}
                name="bank_account_id"
                disabled={isOnlinePaymentType()} />
            </Col2>
            <Col2>
              {displayGatewaySelector()}
            </Col2>
          </Row>
          <Subtitle style={{ marginBottom: '8px' }}>Meta Data</Subtitle>
          <Row height="auto">
            <Col2>
              <MetadataSelector
                type={MetadataType.program}
                onChange={(id) => onFieldChange(id, 'program_id')}
                form={FORM.edit_payment}
                name="program_id"
                value={paymentState.program_id || undefined}
                noMarginTop />
            </Col2>
            <Col2>
              <MetadataSelector
                type={MetadataType.account}
                onChange={(id) => onFieldChange(id, 'account_id')}
                form={FORM.edit_payment}
                name="account_id"
                value={paymentState.account_id || undefined}
                noMarginTop />
            </Col2>
          </Row>
          <Row height="auto">
            <Col2>
              <MetadataSelector
                type={MetadataType.department}
                onChange={(id) => onFieldChange(id, 'department_id')}
                form={FORM.edit_payment}
                name="department_id"
                value={paymentState.department_id || undefined}
                noMarginTop />
            </Col2>
          </Row>
          <Row height="auto">
            <FormTextField
              form={FORM.edit_payment}
              label="Description"
              multiline
              minRows="4"
              name="description"
              onChange={onFieldChange}
              value={paymentState.description || ''} />
          </Row>
        </Form>
      </Scroller>
    </>
  );
};

export default EditPaymentForm;
