import { MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import {
  getOrganisationAction,
  setCurrentOrganisation,
} from 'src/redux/actions/organisationActions';
import {
  Button, Menu, MenuItem, TextField, Theme,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { v4 as uuid } from 'uuid';
import {
  UserOrganisationItemModel,
} from 'src/models/UserOrganisationListModel';

const useStyles = makeStyles((theme: Theme) => createStyles({
  menu: {
    backgroundColor: theme.palette.primary.light,
  },
}));

const OrganisationSelector = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const [currentFilter, setCurrentFilter] = useState('');
  const currentOrganisationData = useSelector((state: Store) => state.organisation);

  const organisationList = useSelector(
    (state: Store) => Object.keys(state.organisationsUser)
      .map((id: string) => state.organisationsUser[id]),
  );

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorElement(null);
  };

  const handleItemClick = (organisation: UserOrganisationItemModel) => {
    dispatch(setCurrentOrganisation(organisation));
    dispatch(getOrganisationAction());
    closeMenu();
  };

  const filterName = (organisation: UserOrganisationItemModel, term: string) => {
    const fr = organisation.name_fr.toLowerCase().indexOf(term.toLowerCase()) > 0;
    const en = organisation.name_en.toLowerCase().indexOf(term.toLowerCase()) > 0;
    return fr || en;
  };

  const displayItems = () => {
    const elements = [] as Array<JSX.Element>;
    organisationList.filter((organisation) => !organisation.hide)
      .filter((organisation) => {
        if (currentFilter) {
          return filterName(organisation, currentFilter);
        }
        return true;
      })
      .forEach((organisation) => {
        elements.push(
          <MenuItem
            key={uuid()}
            onClick={() => handleItemClick(organisation)}>{ organisation.name_en }
          </MenuItem>,
        );
      });
    return elements;
  };

  return (
    <div>
      <Button
        aria-haspopup="true"
        aria-controls="customized-menu"
        className={classes.menu}
        color="primary"
        onClick={openMenu}
        variant="contained"
        title="Current organisation">
        { currentOrganisationData.nameEn || 'Perfect Deed' }
      </Button>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        id="customized-menu"
        getContentAnchorEl={null}
        keepMounted
        onClose={() => closeMenu()}
        open={!!anchorElement}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <div style={{ margin: '0 16px' }}>
          <TextField
            margin="dense"
            variant="outlined"
            label="Filter"
            onChange={(e) => setCurrentFilter(e.target.value)}
            value={currentFilter} />
          { displayItems() }
        </div>
      </Menu>
    </div>
  );
};

export default OrganisationSelector;
