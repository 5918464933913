import { Route } from 'src/constants/routes';

const titles = {
  [Route.CONTACTS]: 'Contacts',
  [Route.INVOICES]: 'Invoices',
  [Route.PAYMENTS]: 'Payments',
  [Route.FUNDRAISING]: 'Events',
  [Route.SETTINGS]: 'Settings',
  [Route.INVOICES_PAYMENT]: 'Invoices For Payment',
  [Route.INVOICES_CONTACT]: 'Invoices For Contact',
  [Route.PAYMENTS_INVOICE]: 'Payments For Invoice',
  [Route.PAYMENTS_CONTACT]: 'Payments For Contact',
  [Route.ORGANISATIONS]: 'Organizations',
};

export default titles;
