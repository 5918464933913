import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { t } from 'src/lib/language';
import { closeModal, openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { MouseEvent, useEffect, useState } from 'react';
import {
  InstallmentModel,
  InvoicePaymentItem,
  PAYMENT_FREQUENCY,
  PAYMENT_FREQUENCY_LIST,
  PAYMENT_TYPE,
  PaymentModel,
} from 'src/models/PaymentModel';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { setError } from 'src/redux/actions/errorsActions';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import GuessPaymentValidator from 'src/validations/GuessPaymentValidator';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { capitalize, isEmpty } from 'lodash';
import { InvoiceModel } from 'src/models/InvoiceModel';
import Subtitle from 'src/components/UI/Subtitle';
import SalutationSelector from 'src/components/Control/SalutationSelector';
import AddressSearch from 'src/components/Control/AddressSearch';
import { AddressModel, AddressType } from 'src/models/AddressModel';
import CountriesSelector from 'src/components/Control/CountriesSelector';
import { postPurchaseAction } from 'src/redux/actions/paymentActions';
import UserPaymentAccountSelector from 'src/components/Control/UserPaymentAccountSelector';
import TextLink from 'src/components/UI/TextLink';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import Header from 'src/components/UI/Header';
import Title from 'src/components/UI/Title';
import Row from 'src/components/UI/Row';
import Scroller from 'src/components/UI/Scroller';
import Form from 'src/components/UI/Form';
import Col2 from 'src/components/UI/Col2';
import { normalizeForForm } from 'src/lib/QueryBuilderHelper';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import { getCountriesAction } from 'src/redux/actions/dataActions';
// import ButtonLink from 'src/components/Elements/ButtonLink';

const UserMakePaymentForm = () => {
  const dispatch = useAppDispatch();
  const bankAccounts = useSelector((state: Store) => state.organisationBankAccounts);
  const organisationId = useSelector((state: Store) => state.currentOrganisation.id);
  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.invoices] || [],
  );
  const processing = useSelector(
    (state: Store) => state.formStatus[FORM.post_purchase] === FORM_STATUS.processing,
  );
  //
  const invoices = useSelector(
    (state: Store) => {
      const list = state.querybuilder.invoices?.result?.list || [];
      const normalized = list
        .filter((invoice) => selections.includes(invoice['invoices.id']))
        .map(
          (invoice) => normalizeForForm(invoice, ENTITIES.invoices),
        ) || [] as InvoiceModel[];
      const unpaid = normalized.filter((invoice) => {
        const balance = invoice.balance ?? 0;
        const scheduled = invoice.scheduled ?? 0;
        const amountToPay = balance - scheduled;
        return amountToPay > 0;
      });
      if (!unpaid.length) return [] as InvoiceModel[];
      return unpaid.filter(
        (invoice) => !!invoice.payment_gateway_cc_id || !!invoice.payment_gateway_eft_id,
      );
    },
  );

  const getInvoicesBalance = () => {
    if (isEmpty(invoices)) return 0;
    const balance = invoices.reduce(
      (total: number, invoice: InvoiceModel) => {
        if (!invoice) {
          return total;
        }
        return total + Number(invoice?.balance);
      },
      0,
    );
    const scheduled = invoices.reduce(
      (total: number, invoice: InvoiceModel) => {
        if (!invoice) {
          return total;
        }
        // @ts-ignore
        return total + Number(invoice?.scheduled);
      },
      0,
    );

    return balance - scheduled;
  };

  const getInvoicesDeductible = () => invoices.reduce(
    (total: number, invoice: InvoiceModel) => {
      if (!invoice) {
        return total;
      }
      // @ts-ignore
      return total + Number(invoice?.deductible);
    },
    0,
  );

  const issueTaxReceipt = () => {
    if (isEmpty(invoices)) return false;
    let result = false;
    invoices.forEach((invoice: InvoiceModel) => {
      result = result || !!invoice.issue_tax_receipt;
    });
    return result;
  };

  const isLogged = useSelector((store: Store) => !!store.auth.accessToken);
  const userContact = useSelector((store: Store) => store.userContact);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const formatMonetaryAmount = (amount: number, locale = 'en-US', decimals = 2) => new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(amount);

  const initializePaymentState = () => ({
    payment_type: invoices[0].payment_gateway_cc_id ? PAYMENT_TYPE.cc : PAYMENT_TYPE.eft,
    scheduled_date: moment().startOf('day'),
    is_installment: false,
    contact_id: (invoices[0] === undefined) ? null : invoices[0].contact_id,
    seller_id: (invoices[0] === undefined) ? null : invoices[0].seller_id,
    amount: formatMonetaryAmount(getInvoicesBalance()),
    deductible: getInvoicesDeductible(),
    is_deductible: getInvoicesDeductible() > 0,
    bank_account_id: (invoices[0] === undefined) ? null : invoices[0].bank_account_id,
    account_id: (invoices[0] === undefined) ? null : invoices[0].account_id,
    program_id: (invoices[0] === undefined) ? null : invoices[0].program_id,
    department_id: (invoices[0] === undefined) ? null : invoices[0].department_id,
    issue_tax_receipt: issueTaxReceipt(),
    payment_gateway_id: 0,
    contact_first_name: invoices[0].contact_first_name,
    contact_last_name: invoices[0].contact_last_name,
    contact_salutation_id: invoices[0].contact_salutation_id,
    contact_company_name: invoices[0].contact_company_name,
    billing_address: invoices[0].billing_address,
    billing_state: invoices[0].billing_state,
    billing_city: invoices[0].billing_city,
    billing_suite: invoices[0].billing_suite,
    billing_country_id: invoices[0].billing_country_id,
    billing_zip_code: invoices[0].billing_zip_code,
  } as PaymentModel);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { payment_gateway_cc_id, payment_gateway_eft_id } = invoices[0];

  const [paymentState, setPaymentState] = useState(initializePaymentState());

  const [installmentState, setInstallmentState] = useState({
    number: 1,
    start_date: moment(),
    frequency: PAYMENT_FREQUENCY.monthly,
  } as InstallmentModel);

  const [paymentType, setPaymentType] = useState(
    payment_gateway_cc_id ? PAYMENT_TYPE.cc : PAYMENT_TYPE.eft,
  );

  useEffect(() => {
    if (!!userContact) {
      setPaymentState({
        ...paymentState,
        contact_salutation_id: userContact.salutation_id || undefined,
        contact_first_name: userContact.first_name,
        contact_last_name: userContact.last_name,
        contact_company_name: userContact.company_name,
      });
    }
  }, [userContact]);

  useEffect(() => {
    dispatch(getCountriesAction());
    setPaymentState(initializePaymentState());
  }, []);

  const setAmount = (value: Value) => {
    if (Number(value) > getInvoicesBalance()) return;
    if (!value) {
      setPaymentState({
        ...paymentState,
        amount: value as string,
      });
      return;
    }
    const stringValue = value as string || '';
    if (!stringValue.match(/^[0-9.]+$/)) return;
    const splittedString = stringValue.split('.');
    if (splittedString.length > 2) return;
    if (splittedString.length === 2) {
      if (splittedString[1].length > 2) return;
    }
    setPaymentState({
      ...paymentState,
      amount: value as string,
    });
  };

  const onFieldChange = (value: Value, field: string) => {
    if (field === 'amount') {
      setAmount(value);
      return;
    }
    setPaymentState({
      ...paymentState,
      [field]: value,
    });
  };

  const displayInstallmentDetails = () => {
    const step = (Number(paymentState.amount) ?? 0) / (installmentState.number ?? 1);
    if (!Number.isNaN(step) && !!installmentState.number) {
      return (
        <p>
          <strong>{installmentState.number} {capitalize(installmentState.frequency)} {t(lang, 'forms.payments.payments_of')} {step.toFixed(2)}$</strong>
        </p>
      );
    }
    return <></>;
  };

  const onInstallmentChange = (value: Value, field: string) => {
    setInstallmentState({
      ...installmentState,
      [field]: value,
    });
  };

  const bankAccountExists = (id: number) => {
    const accounts = bankAccounts[organisationId] ?? [];
    const result = accounts.filter((account: any) => account.id === id);
    return result.length > 0;
  };

  const calculateInvoiceAmounts = () => {
    let remainingBalance = Number(paymentState.amount) ?? 0;
    const invoiceItems:InvoicePaymentItem[] = [];

    if (invoices.length === 1) {
      invoiceItems.push({
        invoice_id: selections[0],
        amount: remainingBalance,
      } as InvoicePaymentItem);
      return invoiceItems;
    }

    invoices.forEach((invoice: InvoiceModel) => {
      const balance = Number(invoice.balance);
      if (remainingBalance) {
        invoiceItems.push({
          invoice_id: invoice.id,
          amount: ((balance ?? 0) < remainingBalance)
            ? Number(invoice.balance)
            : remainingBalance,
        } as InvoicePaymentItem);

        remainingBalance = ((balance ?? 0) < remainingBalance)
          ? remainingBalance - (balance ?? 0)
          : 0;
      }
    });

    return invoiceItems;
  };

  const getBankAccountId = () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { bank_account_id } = paymentState;
    if (bank_account_id && !bankAccountExists(bank_account_id)) {
      return undefined;
    }
    return bank_account_id;
  };

  const displayInstallmentFrequencyOptions = () => (
    PAYMENT_FREQUENCY_LIST.map((frequency: PAYMENT_FREQUENCY, index: number) => (
      <MenuItem value={frequency} key={`frequency-${index}`}>{frequency}</MenuItem>
    ))
  );

  const getData = () => ({
    ...paymentState,
  });

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.makeUserPayment }));
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const data = getData();
    if ([PAYMENT_TYPE.cc, PAYMENT_TYPE.eft].includes(paymentState.payment_type)) {
      data.scheduled_date = data.paid_date;
    }
    dispatch(setError({ [FORM.make_payment]: {} }));
    try {
      GuessPaymentValidator(lang, isLogged)
        .validateSync(data, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: t(lang, 'forms.contact.validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.make_payment]: errorBag }));
      return false;
    }

    const payload = {
      ...paymentState,
      payment_type: paymentType,
      invoices: calculateInvoiceAmounts(),
      installment: paymentState.is_installment ? installmentState : undefined,
      seller_id: paymentState.seller_id ? paymentState.seller_id : undefined,
      bank_account_id: getBankAccountId(),
      payment_gateway_id: paymentType === PAYMENT_TYPE.cc
        ? payment_gateway_cc_id
        : payment_gateway_eft_id,
    } as any;
    dispatch<any>(postPurchaseAction(payload, MODALS.makeUserPayment));
    return true;
  };

  const setAddressFromGoogle = (address: AddressModel, type: AddressType) => {
    setPaymentState({
      ...paymentState,
      [`${type}_address`]: address.address,
      [`${type}_city`]: address.city,
      [`${type}_state`]: address.state,
      [`${type}_country_id`]: address.country_id,
      [`${type}_zip_code`]: address.zip_code,
    });
  };

  const onAddressChange = (address: AddressModel) => {
    setAddressFromGoogle(address, AddressType.billing);
  };

  const openAddCreditCardModal = () => {
    dispatch(openModal({
      modal: MODALS.addUserCreditCard,
    }));
  };

  const displayAddCreditCard = () => {
    if (paymentType !== PAYMENT_TYPE.cc) return (<></>);
    return (
      <TextLink
        onClick={() => openAddCreditCardModal()}>{t(lang, 'forms.payments.add_credit_card')}
      </TextLink>
    );
  };

  return (
    <>
      <Header>
        <Row>
          <Title>{t(lang, 'forms.invoices.make_payment')}</Title>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}>
            Close
          </Button>
        </Row>
      </Header>
      <Scroller height="608px">
        <Form>
          <Row>
            <Col2 responsive>
              <FormTextField
                form={FORM.make_payment}
                label={`${t(lang, 'forms.payments.amount')}(max:${formatMonetaryAmount(getInvoicesBalance())})`}
                name="amount"
                onChange={onFieldChange}
                required
                value={paymentState.amount ?? '00.00'}
                disabled={processing} />
            </Col2>
            <Col2 responsive>
              {!!paymentState.is_installment &&
                (
                  <FormControl
                    component="fieldset"
                    style={{ marginTop: '8px' }}
                    disabled={processing}>
                    <FormLabel
                      component="legend">{t(lang, 'forms.payments.installments')}
                    </FormLabel>
                    <RadioGroup
                      defaultValue={0}
                      row
                      aria-label={t(lang, 'forms.payments.installments')}
                      name="is_installment"
                      value={paymentState.is_installment ? 1 : 0}
                      onChange={(e) => {
                        onFieldChange(e.target.value !== '0', 'is_installment');
                      }}>
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label={t(lang, 'forms.payments.single_payment')} />
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label={t(lang, 'forms.payments.multiple_payment')} />
                    </RadioGroup>
                  </FormControl>
                )}
            </Col2>
          </Row>
          {!!paymentState.is_installment && (
          <>
            <Row>
              <Col2 responsive>
                <FormControl style={{ width: '100%', minWidth: 120, marginTop: '16px' }}>
                  <InputLabel id="frequency">Frequence</InputLabel>
                  <Select
                    labelId="frequency-label-id-"
                    id="demo-simple-select"
                    value={installmentState.frequency || ''}
                    onChange={(e) => onInstallmentChange(e.target.value as string, 'frequency')}
                    disabled={processing}>
                    {displayInstallmentFrequencyOptions()}
                  </Select>
                </FormControl>
              </Col2>
              <Col2 responsive>
                <FormTextField
                  form={FORM.make_payment}
                  label={t(lang, 'forms.payments.number')}
                  name="number"
                  onChange={onInstallmentChange}
                  required
                  value={installmentState.number || ''}
                  disabled={processing} />
              </Col2>
            </Row>
            {displayInstallmentDetails()}
            <Row>
              <Col2>
                <FormDatePicker
                  form={FORM.make_payment}
                  required
                  label={t(lang, 'forms.payments.start_date')}
                  name="start_date"
                  onChange={
                      (value: MaterialUiPickersDate) => onInstallmentChange(
                        moment(value)
                          .format('YYYY-MM-DD'),
                        'start_date',
                      )
                    }
                  value={installmentState.start_date || moment()}
                  disabled={processing} />
              </Col2>
            </Row>
          </>
          )}
          <Subtitle>{t(lang, 'forms.contact.payer_name')}</Subtitle>
          <Row>
            <Col2 responsive>
              <SalutationSelector
                form={FORM.make_payment}
                name="contact_salutation_id"
                value={paymentState.contact_salutation_id || 0}
                onChange={(id) => onFieldChange(id, 'contact_salutation_id')}
                disabled={processing} />
            </Col2>
            <Col2 responsive>
              <FormTextField
                form={FORM.make_payment}
                label={t(lang, 'forms.contact.first_name')}
                name="contact_first_name"
                onChange={onFieldChange}
                value={paymentState.contact_first_name}
                disabled={processing} />
            </Col2>
          </Row>
          <Row>
            <Col2 responsive>
              <FormTextField
                form={FORM.make_payment}
                label={t(lang, 'forms.contact.last_name')}
                name="contact_last_name"
                onChange={onFieldChange}
                value={paymentState.contact_last_name}
                disabled={processing} />
            </Col2>
            <Col2 responsive>
              <FormTextField
                form={FORM.make_payment}
                label={t(lang, 'forms.contact.company_name')}
                name="contact_company_name"
                onChange={onFieldChange}
                value={paymentState.contact_company_name}
                disabled={processing} />
            </Col2>
          </Row>
          <Row>
            <Col2 responsive>
              <AddressSearch
                onChange={onFieldChange}
                onAddressChange={onAddressChange}
                value={{ description: paymentState.billing_address || '' }}
                name="billing_address"
                form={FORM.make_payment}
                disabled={processing} />
            </Col2>
            <Col2 responsive>
              <FormTextField
                form={FORM.make_payment}
                label={t(lang, 'forms.contact.suite')}
                name="billing_suite"
                onChange={onFieldChange}
                value={paymentState.billing_suite || ''}
                disabled={processing} />
            </Col2>
          </Row>
          <Row>
            <Col2 responsive>
              <FormTextField
                form={FORM.make_payment}
                label={t(lang, 'forms.contact.city')}
                name="billing_city"
                onChange={onFieldChange}
                value={paymentState.billing_city || ''}
                disabled={processing} />
            </Col2>
            <Col2 responsive>
              <FormTextField
                form={FORM.make_payment}
                label={t(lang, 'forms.contact.state')}
                name="billing_state"
                onChange={onFieldChange}
                value={paymentState.billing_state || ''}
                disabled={processing} />
            </Col2>
          </Row>
          <Row>
            <Col2 responsive>
              <CountriesSelector
                form={FORM.make_payment}
                onChange={(id) => onFieldChange(id, 'billing_country_id')}
                value={paymentState.billing_country_id || undefined}
                name="billing_country_id"
                disabled={processing} />
            </Col2>
            <Col2 responsive>
              <FormTextField
                form={FORM.make_payment}
                label={t(lang, 'forms.contact.zip_code')}
                name="billing_zip_code"
                onChange={onFieldChange}
                value={paymentState.billing_zip_code || ''}
                disabled={processing} />
            </Col2>
          </Row>
          <Subtitle>{t(lang, 'forms.contact.payment_information')}</Subtitle>
          <Row>
            <FormControl
              component="fieldset"
              style={{ marginTop: '8px' }}
              disabled={processing}>
              <RadioGroup
                row
                aria-label="bank_country"
                name="bank_country"
                value={paymentType}
                onChange={(e) => setPaymentType(e.target.value as PAYMENT_TYPE)}>
                { !!payment_gateway_cc_id && (
                <FormControlLabel
                  value={PAYMENT_TYPE.cc}
                  control={<Radio />}
                  label="Credit Card" />
                )}
                { !!payment_gateway_eft_id && (
                <FormControlLabel
                  value={PAYMENT_TYPE.eft}
                  control={<Radio />}
                  label="Bank Account" />
                )}
              </RadioGroup>
            </FormControl>
          </Row>
          <UserPaymentAccountSelector
            type={paymentType}
            form={FORM.make_payment}
            name="payment_info_id"
            onChange={(id: number) => onFieldChange(id, 'payment_info_id')}
            value={paymentState.payment_info_id}
            disabled={processing} />
          {displayAddCreditCard()}
          <Row style={{ marginTop: '8px' }}>
            <Button
              key="submit-close-button"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={processing}>
              {t(lang, 'misc.pay')}
            </Button>
          </Row>
        </Form>
      </Scroller>
    </>
  );
};

export default UserMakePaymentForm;
