import { useEffect } from 'react';
import { Store, useAppDispatch } from 'src/redux/Store';
import { AuthModel } from 'src/models/AuthModel';
import { useSelector } from 'react-redux';
import LoginLayout from 'src/components/Layout/LoginLayout';
import PageLayout from 'src/components/Layout/PageLayout';
import { useLocation } from 'react-router-dom';
import routes, { Route } from 'src/constants/routes';
import { setRouteData } from 'src/redux/actions/routeActions';
import { setFormStatus } from 'src/redux/actions/formActions';
import { FORM } from 'src/constants/Form';
import {
  getOrganisationAction,
  getOrganisationsUserAction,
} from 'src/redux/actions/organisationActions';
import { getTimeZoneAction } from 'src/redux/actions/dataActions';
import GuessLayout from 'src/components/Layout/GuessLayout';
import { resetDialog } from 'src/redux/actions/dialogActions';
import { resetModal } from 'src/redux/actions/modalActions';
import moment from 'moment-timezone-all';
import { getUserDataAction } from 'src/redux/actions/userActions';

interface Props {
  content: Route
}

const Page = (props: Props) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { hasContact, verified, hasOrganizations } = useSelector((state: Store) => (
    {
      hasContact: state.user.has_contact,
      verified: state.user.verified,
      hasOrganizations: state.user.has_organisations,
    }
  ));
  const { content } = props;

  const getId = () => {
    const id = location.pathname.split('/')
      .pop();
    if (Number.isNaN(Number(id))) {
      return 0;
    }
    return id;
  };

  useEffect(() => {
    Object.keys(routes)
      .forEach(
        (name: string) => {
          if (routes[name].content === content) {
            dispatch(setRouteData({
              ...routes[name],
              id: getId(),
            }));
          }
        },
      );
    dispatch(setFormStatus({ [FORM.verifying]: null }));
    dispatch(setFormStatus({ [FORM.reset_password]: null }));
    dispatch(getOrganisationAction());
    dispatch(getOrganisationsUserAction());
    dispatch(resetDialog());
    dispatch(resetModal());
    dispatch(getTimeZoneAction());
    dispatch(getUserDataAction());
  }, []);

  const REDIRECT_TO_PROFILE = [Route.RESET_PASSWORD];

  const auth: AuthModel = useSelector((store: Store) => store.auth);
  const organisationTimezone = useSelector((store: Store) => store.organisation.timezoneId);
  const timezone = useSelector((store: Store) => store.data.timezones[organisationTimezone]);
  const localeId = useSelector((store: Store) => store.user.localeId || 'en_CA');

  if (timezone) {
    moment.tz.setDefault(timezone);
  }
  moment.locale(localeId);

  switch (true) {
    case content === Route.GUESS_INVOICE:
      return (<GuessLayout content={Route.GUESS_INVOICE} />);
    case content === Route.VERIFY:
      return (<LoginLayout content={Route.VERIFY} />);
    case content === Route.LOGOUT:
      return (<LoginLayout content={Route.LOGOUT} />);
    case !auth.accessToken && !auth.remember:
      return (<LoginLayout content={content} />);
    case auth.accessToken && !verified:
      return (<LoginLayout content={Route.NOT_VERIFIED} />);
    case !hasContact:
      return (<PageLayout content={Route.PROFILE} />);
    case !hasOrganizations:
      return (<PageLayout content={Route.ORGANISATIONS} />);
    default:
      if (REDIRECT_TO_PROFILE.includes(content)) {
        return (<PageLayout content={Route.PROFILE} />);
      }
      return (<PageLayout content={content} />);
  }
};

export default Page;
