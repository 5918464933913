import { useSelector } from 'react-redux';
import { MouseEvent, useEffect, useState } from 'react';
import { Store, useAppDispatch } from 'src/redux/Store';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import AttachMoney from '@material-ui/icons/AttachMoney';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  FormControl, FormControlLabel, Radio, RadioGroup, Tooltip,
} from '@material-ui/core';
import { t } from 'src/lib/language';
import {
  capitalize, ceil, isEmpty, keyBy,
} from 'lodash';
import PaymentValidator from 'src/validations/PaymentValidator';

import {
  InstallmentModel,
  InvoicePaymentItem,
  PAYMENT_ACTION_TYPE,
  PAYMENT_FREQUENCY,
  PAYMENT_FREQUENCY_LIST,
  PAYMENT_STATUS,
  PAYMENT_TYPE,
  PaymentModel,
} from 'src/models/PaymentModel';
import { ErrorBag } from 'src/models/ErrorModel';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { MODALS } from 'src/models/ModalModel';
import { ProcessingType } from 'src/models/GatewayModel';
import { SelectionPayload } from 'src/models/SelectionModel';
import { InvoiceModel } from 'src/models/InvoiceModel';

import { ALERT_TYPE } from 'src/constants/AlertType';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { INVOICE_FIELDS } from 'src/constants/Invoices';
import { AUTOCOMPLETE_ID } from 'src/constants/Autocomplete';

import { closeModal, openModal } from 'src/redux/actions/modalActions';
import { setError } from 'src/redux/actions/errorsActions';
import { setCurrentEntity } from 'src/redux/actions/currentEntityActions';
import { postPaymentAction } from 'src/redux/actions/paymentActions';
import { getGatewayListAction } from 'src/redux/actions/gatewayAction';
import { setAlert } from 'src/redux/actions/alertActions';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import ContactSelector from 'src/components/Control/ContactSelector';
import PaymentAccountSelector from 'src/components/Control/PaymentAccountSelector';
import OrganisationBankAccountSelector
  from 'src/components/Control/OrganisationBankAccountSelector';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import TextLink from 'src/components/UI/TextLink';
import FormSingleSelect from 'src/components/Control/FormControls/FormSingleSelect';
import Subtitle from 'src/components/UI/Subtitle';
import MetadataSelector, { MetadataType } from 'src/components/Control/MetadataSelector';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import GatewaySelector from 'src/components/Control/GatewaySelector';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import { Moment } from 'moment';
import Header from 'src/components/UI/Header';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';
import Scroller from 'src/components/UI/Scroller';
import Form from 'src/components/UI/Form';
import Col2 from 'src/components/UI/Col2';

const MakePaymentForm = () => {
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const modalPayload = useSelector((state: Store) => {
    if (state.modals[MODALS.makePayment]) {
      return state.modals[MODALS.makePayment].payload as SelectionPayload;
    }
    return null;
  });
  const organisationId = useSelector((state: Store) => state.currentOrganisation.id);
  const bankAccounts = useSelector((state: Store) => state.organisationBankAccounts);
  const gateways = useSelector((state: Store) => state.gateways);
  const processing = useSelector(
    (state: Store) => state.formStatus[FORM.make_payment] === FORM_STATUS.processing,
  );
  const selections = useSelector(
    (state: Store) => modalPayload || state.selected[ENTITIES.invoices] || [],
  );
  const invoices = useSelector((state: Store) => selections.map(
    (id: number) => state.invoices[id],
  ) || [] as InvoiceModel[]);

  const getInvoicesBalance = () => {
    if (isEmpty(invoices)) return 0;
    const balance = invoices.reduce(
      (total:number, invoice:InvoiceModel) => {
        if (!invoice) {
          return total;
        }
        return total + Number(invoice?.balance);
      },
      0,
    );
    const scheduled = invoices.reduce(
      (total:number, invoice:InvoiceModel) => {
        if (!invoice) {
          return total;
        }
        return total + Number(invoice?.scheduled);
      },
      0,
    );
    return balance - scheduled;
  };

  const getInvoicesDeductible = () => invoices.reduce(
    (total:number, invoice:InvoiceModel) => {
      if (!invoice) {
        return total;
      }
      return total + Number(invoice?.deductible);
    },
    0,
  );

  const issueTaxReceipt = () => {
    if (isEmpty(invoices)) return false;
    let result = false;
    invoices.forEach((invoice:InvoiceModel) => {
      result = result || !!invoice.issue_tax_receipt;
    });
    return result;
  };

  const getInitialTitle = () => {
    if (invoices[0] === undefined) {
      return '';
    }

    if (invoices.length === 1) {
      return invoices[0].title;
    }

    return invoices.map((invoice: InvoiceModel) => invoice.invoice_number).join('/');
  };

  const [paymentState, setPaymentState] = useState({
    payment_type: PAYMENT_TYPE.cc,
    payment_action: PAYMENT_ACTION_TYPE.credit,
    payment_status: PAYMENT_STATUS.scheduled,
    scheduled_date: moment().startOf('day'),
    is_installment: false,
    title: getInitialTitle(),
    contact_id: (invoices[0] === undefined) ? null : invoices[0].contact_id,
    seller_id: (invoices[0] === undefined) ? null : invoices[0].seller_id,
    amount: getInvoicesBalance(),
    deductible: getInvoicesDeductible(),
    bank_account_id: (invoices[0] === undefined) ? null : invoices[0].bank_account_id,
    account_id: (invoices[0] === undefined) ? null : invoices[0].account_id,
    program_id: (invoices[0] === undefined) ? null : invoices[0].program_id,
    department_id: (invoices[0] === undefined) ? null : invoices[0].department_id,
    issue_tax_receipt: issueTaxReceipt(),
    paid_date: undefined,
    start_date: undefined,
    processed_date: undefined,
    payment_gateway_cc_id: invoices[0].payment_gateway_cc_id,
    payment_gateway_eft_id: invoices[0].payment_gateway_eft_id,
  } as PaymentModel);

  const [installmentState, setInstallmentState] = useState({
    number: 1,
    start_date: moment().startOf('day'),
    frequency: PAYMENT_FREQUENCY.monthly,
  } as InstallmentModel);
  const [gatewayCcBankAccountId, setGatewayCcBankAccountId] = useState(0);
  const [gatewayEftBankAccountId, setGatewayEftBankAccountId] = useState(0);

  useEffect(() => {
    dispatch(setError({ [FORM.make_payment]: {} }));
    dispatch(getGatewayListAction());
  }, []);

  useEffect(() => {
    if (!!paymentState.contact_id) {
      dispatch(setCurrentEntity({ entity: ENTITIES.contacts, id: paymentState.contact_id }));
    }
  }, [paymentState.contact_id]);

  const bankAccountExists = (id: number) => {
    const accounts = bankAccounts[organisationId] ?? [];
    const result = accounts.filter((account: any) => account.id === id);
    return result.length > 0;
  };

  const isOnlinePayment =
    () => [PAYMENT_TYPE.cc, PAYMENT_TYPE.eft].includes(paymentState.payment_type);

  const isOfflinePayment =
  () => ![PAYMENT_TYPE.cc, PAYMENT_TYPE.eft].includes(paymentState.payment_type);

  const calculateInvoiceAmounts = () => {
    let remainingBalance = Number(paymentState.amount) ?? 0;
    const invoiceItems:InvoicePaymentItem[] = [];

    if (invoices.length === 1) {
      invoiceItems.push({
        invoice_id: selections[0],
        amount: remainingBalance,
      } as InvoicePaymentItem);
      return invoiceItems;
    }

    invoices.forEach((invoice: InvoiceModel) => {
      const balance = Number(invoice.balance);
      if (remainingBalance) {
        invoiceItems.push({
          invoice_id: invoice.id,
          amount: ((balance ?? 0) < remainingBalance)
            ? Number(invoice.balance)
            : remainingBalance,
        } as InvoicePaymentItem);

        remainingBalance = ((balance ?? 0) < remainingBalance)
          ? remainingBalance - (balance ?? 0)
          : 0;
      }
    });

    return invoiceItems;
  };

  const getDate = (value?: Nullable<Moment>) => {
    if (!value) return null;
    return moment(value);
  };

  const getBankAccountId = () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { bank_account_id } = paymentState;
    if (bank_account_id && !bankAccountExists(bank_account_id)) {
      return undefined;
    }
    return bank_account_id;
  };

  const getPaymentInfoId = () => (isOnlinePayment() ? paymentState.payment_info_id : undefined);

  const getProcessedDate = () => {
    if (isOnlinePayment()) return undefined;
    if (paymentState.payment_status !== PAYMENT_STATUS.paid) return undefined;
    if (paymentState.is_installment) return undefined;
    return paymentState.processed_date;
  };

  const getDates = () => {
    if (paymentState.is_installment) {
      return {
        paid_date: undefined,
        scheduled_date: undefined,
        start_date: paymentState.start_date,
        processed_date: getProcessedDate(),
      };
    }
    if (paymentState.payment_status === PAYMENT_STATUS.paid) {
      return {
        paid_date: paymentState.paid_date,
        scheduled_date: undefined,
        start_date: undefined,
        processed_date: getProcessedDate(),
      };
    }
    return {
      paid_date: undefined,
      scheduled_date: paymentState.scheduled_date,
      start_date: undefined,
      processed_date: getProcessedDate(),
    };
  };

  const getOnlinePaymentStatus = () => (
    [
      {
        display: PAYMENT_STATUS.scheduled,
        id: PAYMENT_STATUS.scheduled,
      },
    ]
  );

  const getOfflinePaymentStatus = () => (
    [
      {
        display: PAYMENT_STATUS.paid,
        id: PAYMENT_STATUS.paid,
      },
      {
        display: PAYMENT_STATUS.scheduled,
        id: PAYMENT_STATUS.scheduled,
      },
    ]
  );

  const openAddCreditCardModal = () => {
    dispatch(openModal({
      modal: MODALS.addCreditCard,
      payload: {
        contact_id: paymentState.contact_id,
      },
    }));
  };

  const openAddBankAccountModal = () => {
    dispatch(openModal({
      modal: MODALS.addBankAccount,
      payload: {
        contact_id: paymentState.contact_id,
      },
    }));
  };

  const onInstallmentChange = (value: Value, field: string) => {
    setInstallmentState({
      ...installmentState,
      [field]: value,
    });
  };

  const onAmountChange = (value: Value, field: string) => {
    if (!value) {
      setPaymentState({
        ...paymentState,
        amount: value as string,
      });
      return;
    }
    const stringValue = value as string || '';
    if (!stringValue.match(/^[0-9.]+$/)) return;
    const splittedString = stringValue.split('.');
    if (splittedString.length > 2) return;
    if (splittedString.length === 2) {
      if (splittedString[1].length > 2) return;
    }
    setPaymentState({
      ...paymentState,
      [field]: value as string,
    });
  };

  const onFieldChange = (value: Value, field: string) => {
    setPaymentState({
      ...paymentState,
      [field]: value,
    });
  };

  const onPaymentTypeChange = (value: PAYMENT_TYPE) => {
    if (isOnlinePayment()) {
      setPaymentState({
        ...paymentState,
        bank_account_id: (value === PAYMENT_TYPE.cc)
          ? gatewayCcBankAccountId
          : gatewayEftBankAccountId,
        payment_type: value,
        payment_status: PAYMENT_STATUS.scheduled,
      });
      return;
    }

    setPaymentState({
      ...paymentState,
      payment_type: value,
    });
  };

  const onGatewayChange = (value: number) => {
    const selectedGateway = keyBy(gateways, 'id')[value];
    if (selectedGateway.bank_account_id) {
      if (paymentState.payment_type === PAYMENT_TYPE.cc) {
        setGatewayCcBankAccountId(selectedGateway.bank_account_id);
      } else {
        setGatewayEftBankAccountId(selectedGateway.bank_account_id);
      }

      setPaymentState({
        ...paymentState,
        payment_gateway_id: value,
        bank_account_id: selectedGateway.bank_account_id,
      });
    }
  };

  const DisplayPaymentTypes = () => {
    const PaymentTypeStyle = {
      margin: '0px',
      marginLeft: '-8px',
      marginTop: '-8px',
    };
    return (
      <>
        <Row>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.cc}
              control={<Radio />}
              label={PAYMENT_TYPE.cc}
              key="payment-type-7" />
          </Col2>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.eft}
              control={<Radio />}
              label={PAYMENT_TYPE.eft}
              key="payment-type-8" />
          </Col2>
        </Row>
        <Row>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.cash}
              control={<Radio />}
              label={PAYMENT_TYPE.cash}
              key="payment-type-0" />
          </Col2>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.check}
              control={<Radio />}
              label={PAYMENT_TYPE.check}
              key="payment-type-1" />
          </Col2>
        </Row>
        <Row>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.inkind}
              control={<Radio />}
              label={PAYMENT_TYPE.inkind}
              key="payment-type-2" />
          </Col2>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.interac}
              control={<Radio />}
              label={PAYMENT_TYPE.interac}
              key="payment-type-3" />
          </Col2>
        </Row>
        <Row>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.terminal}
              control={<Radio />}
              label={PAYMENT_TYPE.terminal}
              key="payment-type-4" />
          </Col2>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.userbalance}
              control={<Radio />}
              label={PAYMENT_TYPE.userbalance}
              key="payment-type-5" />
          </Col2>
        </Row>
        <Row>
          <Col2>
            <FormControlLabel
              disabled={processing}
              style={PaymentTypeStyle}
              value={PAYMENT_TYPE.wired}
              control={<Radio />}
              label={PAYMENT_TYPE.wired}
              key="payment-type-6" />
          </Col2>
        </Row>
      </>
    );
  };

  const DisplayPaymentStatusSelector = () => {
    if (isOfflinePayment()) {
      return (
        <FormSingleSelect
          form={FORM.make_payment}
          name="paymentStatus"
          label={t(lang, 'forms.invoices.payment_status')}
          onChange={(status: string) => onFieldChange(status, 'payment_status')}
          value={paymentState.payment_status}
          items={getOfflinePaymentStatus()}
          disabled={processing}
          noMarginTop />
      );
    }
    return (
      <FormSingleSelect
        form={FORM.make_payment}
        name="paymentStatus"
        label={t(lang, 'forms.invoices.payment_status')}
        onChange={(status: string) => onFieldChange(status, 'payment_status')}
        value={paymentState.payment_status || PAYMENT_STATUS.scheduled}
        items={getOnlinePaymentStatus()}
        disabled
        noMarginTop />
    );
  };

  const DisplayInstallmentFrequencyOptions = () => (
    <Select
      labelId="frequency-label-id-"
      id="demo-simple-select"
      value={installmentState.frequency || ''}
      onChange={(e) => onInstallmentChange(e.target.value as string, 'frequency')}
      disabled={processing}>
      { PAYMENT_FREQUENCY_LIST.map((frequency:PAYMENT_FREQUENCY, index:number) => (
        <MenuItem value={frequency} key={`payment-frequency-${index}`}>{frequency}</MenuItem>
      ))}
    </Select>
  );

  const DisplayInstallmentDetails = () => {
    const step = (Number(paymentState.amount) ?? 0) / (installmentState.number ?? 1);

    if (!Number.isNaN(step) && !!installmentState.number) {
      return (
        <span style={{ fontStyle: 'italic' }}>
          {installmentState.number} {capitalize(installmentState.frequency)}&nbsp;
          {t(lang, 'forms.payments.payments_of')}&nbsp;
          {step.toFixed(2)}$
        </span>
      );
    }
    return <></>;
  };

  const getGatewayType = (paymentType: PAYMENT_TYPE) => {
    if (paymentType === PAYMENT_TYPE.cc) return ProcessingType.cc;
    if (paymentType === PAYMENT_TYPE.eft) return ProcessingType.eft;
    return undefined;
  };

  const DisplayPaymentInfoSelector = () => {
    if (paymentState.payment_type === PAYMENT_TYPE.cc) {
      return (
        <TextLink onClick={() => openAddCreditCardModal()}>{t(lang, 'forms.payments.add_credit_card')}</TextLink>
      );
    }
    if (paymentState.payment_type === PAYMENT_TYPE.eft) {
      return (
        <TextLink onClick={() => openAddBankAccountModal()}>{t(lang, 'forms.payments.add_bank_account')}</TextLink>
      );
    }
    return (<></>);
  };

  const DisplaySaveIcon = () => {
    if (processing) {
      return (
        <div style={{ padding: '2px', height: '24px' }}>
          <CircularProgress color="inherit" size="20px" />
        </div>
      );
    }
    return (<AttachMoney />);
  };

  const DisplayProcessedDate = () => {
    if (isOnlinePayment()) return (<></>);
    if (paymentState.payment_status !== PAYMENT_STATUS.paid) return (<></>);
    if (paymentState.is_installment) return (<></>);
    return (
      <Row height="auto">
        <Col2>
          <FormDatePicker
            form={FORM.make_payment}
            required
            label={t(lang, 'forms.payments.processed_date')}
            name="processed_date"
            onChange={
              (value: MaterialUiPickersDate) => onFieldChange(
                value,
                'processed_date',
              )
            }
            value={getDate(paymentState.processed_date)} />
        </Col2>
        <Col2>&nbsp;</Col2>
      </Row>
    );
  };

  const DisplayDate = () => {
    if (paymentState.is_installment) {
      return (
        <FormDatePicker
          form={FORM.make_payment}
          required
          label={t(lang, 'forms.payments.start_date')}
          name="start_date"
          onChange={
            (value: MaterialUiPickersDate) => onInstallmentChange(
              value,
              'start_date',
            )
          }
          value={getDate(installmentState.start_date)} />
      );
    }

    if (isOnlinePayment()) {
      return (
        <FormDatePicker
          form={FORM.make_payment}
          required
          label={t(lang, 'forms.payments.paid_date')}
          name="scheduled_date"
          onChange={
            (value: MaterialUiPickersDate) => onFieldChange(
              value,
              'scheduled_date',
            )
          }
          value={getDate(paymentState.scheduled_date)}
          disabled={processing} />
      );
    }
    if (isOfflinePayment()) {
      return (
        <FormDatePicker
          form={FORM.make_payment}
          required
          label={t(lang, 'forms.payments.paid_date')}
          name="paid_date"
          onChange={
            (value: MaterialUiPickersDate) => onFieldChange(
              value,
              'paid_date',
            )
          }
          value={getDate(paymentState.paid_date)}
          disabled={processing} />
      );
    }

    return <></>;
  };

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.makePayment }));
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>, close:boolean = false) => {
    event.preventDefault();
    dispatch(setError({ [FORM.make_payment]: {} }));
    const data = {
      ...paymentState,
    };
    try {
      PaymentValidator(lang).validateSync({ ...data }, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: t(lang, 'forms.contact.validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.make_payment]: errorBag }));
      return false;
    }

    // INSTALLMENT
    if (paymentState.is_installment) {
      const payload = {
        ...paymentState,
        paid_date: getDates().paid_date,
        scheduled_date: getDates().scheduled_date,
        start_date: getDates().start_date,
        processed_date: getDates().processed_date,
        bank_account_id: getBankAccountId(),
        payment_info_id: getPaymentInfoId(),
        invoices: calculateInvoiceAmounts(),
        installment: installmentState,
      };
      dispatch<any>(postPaymentAction(payload, close));
      return true;
    }

    // ONLINE
    if (isOnlinePayment()) {
      const payload = {
        ...paymentState,
        paid_date: getDates().paid_date,
        scheduled_date: getDates().scheduled_date,
        start_date: getDates().start_date,
        processed_date: getDates().processed_date,
        bank_account_id: getBankAccountId(),
        invoices: calculateInvoiceAmounts(),
        installment: undefined,
      };
      dispatch<any>(postPaymentAction(payload, close));
      return true;
    }
    // OFFLINE
    const payload = {
      ...paymentState,
      payment_info_id: undefined,
      paid_date: getDates().paid_date,
      scheduled_date: getDates().scheduled_date,
      start_date: getDates().start_date,
      processed_date: getDates().processed_date,
      bank_account_id: getBankAccountId(),
      invoices: calculateInvoiceAmounts(),
      installment: undefined,
    };
    dispatch<any>(postPaymentAction(payload, close));
    return true;
  };

  const CloseButton = () => (
    <Button
      style={{ marginLeft: 'auto' }}
      variant="contained"
      color="primary"
      size="small"
      onClick={handleClose}>
      Close
    </Button>
  );

  const SaveButton = () => (
    <Tooltip
      title={t(lang, 'menus.make_payment')}
      placement="top-start">
      <span>
        <ActionButton
          category={MENU_BUTTON_CATEGORY.action}
          onClick={handleSubmit}>
          {DisplaySaveIcon()}
        </ActionButton>
      </span>
    </Tooltip>
  );

  return (
    <>
      <Header height="82px">
        <Row height="auto">
          <Title>{t(lang, 'forms.invoices.make_payment')}</Title>
          <CloseButton />
        </Row>
        <Row>
          <SaveButton />
        </Row>
      </Header>
      <Scroller height="608px">
        <Form>
          <Subtitle noMargin>Title</Subtitle>
          <Row height="auto">
            <FormTextField
              form={FORM.make_payment}
              label={t(lang, 'forms.invoices.title')}
              name="title"
              onChange={onFieldChange}
              required
              value={paymentState.title} />
          </Row>
          <Subtitle>Contacts</Subtitle>
          <Row height="96px">
            <ContactSelector
              autocompleteId={AUTOCOMPLETE_ID.add_payment_contact}
              onChange={(id: number) => onFieldChange(id, INVOICE_FIELDS.contact_id)}
              label={t(lang, 'forms.payments.payer')}
              form={FORM.make_payment}
              name={INVOICE_FIELDS.contact_id}
              contactId={paymentState.contact_id}
              style={{ marginTop: '16px' }} />
          </Row>
          <Row height="96px">
            <ContactSelector
              autocompleteId={AUTOCOMPLETE_ID.add_payment_contact}
              onChange={(id: number) => onFieldChange(id, INVOICE_FIELDS.seller_id)}
              label={t(lang, 'forms.payments.seller')}
              form={FORM.make_payment}
              name={INVOICE_FIELDS.seller_id}
              contactId={paymentState.seller_id} />
          </Row>
          <Subtitle>Amounts</Subtitle>
          <Row height="auto" style={{ marginTop: '8px' }}>
            <b>Balance:</b>{ceil(getInvoicesBalance(), 2)}
          </Row>
          <Row height="auto">
            <Col2>
              <FormTextField
                form={FORM.make_payment}
                label={t(lang, 'forms.payments.amount')}
                name="amount"
                onChange={onAmountChange}
                required
                value={paymentState.amount ?? '0.00'} />
            </Col2>
          </Row>
          <Row height="auto">
            <FormControl component="fieldset" style={{ marginTop: '8px' }}>
              <FormCheckbox
                name="issue_tax_receipt"
                label={t(lang, 'forms.payments.issue_tax_receipt')}
                checked={!!paymentState.issue_tax_receipt}
                onChange={onFieldChange} />
            </FormControl>
          </Row>
          <Subtitle>Payment type</Subtitle>
          <Row height="auto" style={{ marginTop: '16px' }}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label={t(lang, 'forms.payments.payment_type')}
                name="payment_type"
                value={paymentState.payment_type}
                onChange={
                (e) => onPaymentTypeChange(e.target.value as PAYMENT_TYPE)
              }>
                <DisplayPaymentTypes />
              </RadioGroup>
            </FormControl>
          </Row>
          {isOnlinePayment() && (
          <Row height="auto">
            <Col2>
              <PaymentAccountSelector
                value={paymentState.payment_info_id || undefined}
                type={paymentState.payment_type}
                onChange={(id: number) => onFieldChange(id, 'payment_info_id')}
                form={FORM.make_payment}
                name="payment_info_id"
                contactId={paymentState.contact_id as number ?? 1} />
            </Col2>
            <Col2>
              <DisplayPaymentInfoSelector />
            </Col2>
          </Row>
          )}
          <Row height="auto" alignItems="start">
            <Col2>
              <OrganisationBankAccountSelector
                style={{ minWidth: '100%' }}
                value={paymentState.bank_account_id || undefined}
                onChange={(id: number) => onFieldChange(id, 'bank_account_id')}
                form={FORM.make_payment}
                name="bank_account_id"
                disabled={processing || isOnlinePayment()} />
            </Col2>
            <Col2>
              <GatewaySelector
                form={FORM.make_payment}
                name="payment_gateway_id"
                onChange={onGatewayChange}
                type={getGatewayType(paymentState.payment_type)}
                value={paymentState.payment_gateway_id}
                noMarginTop />
            </Col2>
          </Row>
          <Row height="auto">
            <Col2>
              <DisplayPaymentStatusSelector />
            </Col2>
            <Col2>
              <DisplayDate />
            </Col2>
          </Row>
          <DisplayProcessedDate />
          <Row height="auto">
            <FormControl component="fieldset">
              <FormCheckbox
                name="is_installment"
                label={t(lang, 'forms.payments.is_installment')}
                checked={!!paymentState.is_installment}
                onChange={onFieldChange}
                disabled={processing} />
            </FormControl>
          </Row>
          {!!paymentState.is_installment && (
          <>
            <Row height="auto">
              <Col2>
                <FormTextField
                  form={FORM.make_payment}
                  label={t(lang, 'forms.payments.number')}
                  name="number"
                  onChange={onInstallmentChange}
                  required
                  disabled={processing}
                  value={installmentState.number || ''} />
              </Col2>
              <Col2>
                <FormControl style={{ width: '100%', minWidth: 120 }}>
                  <InputLabel id="frequency">Frequency</InputLabel>
                  <DisplayInstallmentFrequencyOptions />
                </FormControl>
              </Col2>
            </Row>
            <Row height="auto">
              <DisplayInstallmentDetails />
            </Row>
          </>
          )}
          <Subtitle>Meta Data</Subtitle>
          <Row height="auto">
            <Col2>
              <MetadataSelector
                type={MetadataType.program}
                onChange={(id) => onFieldChange(id, 'program_id')}
                form={FORM.make_payment}
                name="program_id"
                value={paymentState.program_id || undefined}
                noMarginTop />
            </Col2>
            <Col2>
              <MetadataSelector
                type={MetadataType.account}
                onChange={(id) => onFieldChange(id, 'account_id')}
                form={FORM.make_payment}
                name="account_id"
                value={paymentState.account_id || undefined}
                noMarginTop />
            </Col2>
          </Row>
          <Row height="auto">
            <Col2>
              <MetadataSelector
                type={MetadataType.department}
                onChange={(id) => onFieldChange(id, 'department_id')}
                form={FORM.make_payment}
                name="department_id"
                value={paymentState.department_id || undefined}
                noMarginTop />
            </Col2>
          </Row>
          <Row height="auto">
            <FormTextField
              form={FORM.make_payment}
              label="Notes"
              multiline
              minRows="4"
              name="description"
              onChange={onFieldChange}
              value={paymentState.description || ''} />
          </Row>
        </Form>
      </Scroller>
    </>
  );
};

export default MakePaymentForm;
