import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import AttachMoney from '@material-ui/icons/AttachMoney';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { t } from 'src/lib/language';
import { MODALS } from 'src/models/ModalModel';
import { openModal } from 'src/redux/actions/modalActions';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { InvoiceModel } from 'src/models/InvoiceModel';

interface OwnProps {
  invoiceId?: number,
}

type Props = OwnProps & ButtonProps;

const UserMakePaymentButton = (props: Props) => {
  const { disabled, invoiceId } = props;
  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language);
  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.invoices] || [],
  );
  const invoices = useSelector(
    (state: Store) => selections.map(
      (id: number) => state.invoices[id],
    ) || [] as InvoiceModel[],
  );

  const getInvoices = () => {
    if (!invoiceId) return invoices;
    return invoices.filter((invoice: InvoiceModel) => invoice.id === invoiceId);
  };

  const handleOnClick = () => {
    if (!invoices.length) return;
    dispatch(openModal({ modal: MODALS.makeUserPayment, payload: getInvoices() }));
  };

  return (
    <Tooltip
      title={t(lang, 'menus.make_payment')}
      placement="top-start">
      <span>
        <ActionButton
          variant="outlined"
          category={MENU_BUTTON_CATEGORY.action}
          onClick={handleOnClick}
          disabled={disabled || !invoices.length}>
          <AttachMoney />
        </ActionButton>
      </span>
    </Tooltip>
  );
};

export default UserMakePaymentButton;
