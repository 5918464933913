import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { useMediaQuery } from 'react-responsive';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { OrderColumn } from 'src/components/Elements/OrderDisplayer';

interface Props {
  canDeleteProduct?: boolean,
  disabled?: boolean,
}

const OrderDisplayerHeader = (props: Props) => {
  const lang = useSelector((state: Store) => state.language.language);

  const {
    canDeleteProduct,
    disabled,
  } = props;

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });

  const Divider = () => (
    <div style={{
      width: '1px',
      height: '20px',
      backgroundColor: 'rgba(0, 0, 0, .75)',
    }} />
  );

  const getColumnStyle = (column: string) => ({
    fontWeight: 'bold',
    padding: '0 8px',
    width: OrderColumn[column],
    minWidth: column === 'product' ? undefined : OrderColumn[column],
  } as CSSProperties);

  const ActionButtonColumn = () => {
    if (!canDeleteProduct || disabled) return (<></>);
    return (
      <>
        <div style={getColumnStyle('actions')} />
        <Divider />
      </>
    );
  };

  const showDesktop = () => (
    <div style={{
      marginTop: '16px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.1)',
      height: '40px',
      gap: '10px',
    }}>
      { ActionButtonColumn() }
      <div style={getColumnStyle('quantity')}>
        {t(lang, 'forms.invoices.quantity')}
      </div>
      <Divider />
      <div style={getColumnStyle('product')}>
        {t(lang, 'forms.invoices.product')}
      </div>
      <Divider />
      <div style={getColumnStyle('price')}>
        {t(lang, 'forms.invoices.price')}
      </div>
      <Divider />
      <div style={getColumnStyle('taxes')}>
        {t(lang, 'forms.invoices.taxes')}
      </div>
      <Divider />
      <div style={getColumnStyle('discount')}>
        {t(lang, 'forms.invoices.discount')}
      </div>
      <Divider />
      <div style={getColumnStyle('deductible')}>
        {t(lang, 'forms.invoices.deductible')}
      </div>
      <Divider />
      <div style={getColumnStyle('amount')}>
        {t(lang, 'forms.invoices.amount')}
      </div>
    </div>
  );

  const showTablet = () => (
    <div style={{
      marginTop: '16px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.1)',
      height: '40px',
    }}>
      <div style={getColumnStyle('product')}>
        {t(lang, 'forms.invoices.product')}
      </div>
      <div style={getColumnStyle('amount')}>
        {t(lang, 'forms.invoices.amount')}
      </div>
    </div>
  );

  return isTabletOrMobile ? showTablet() : showDesktop();
};

export default OrderDisplayerHeader;
