import { InvoiceModel } from 'src/models/InvoiceModel';

export const normalizeInvoiceForUser = (invoice: any): InvoiceModel => {
  const result = {} as InvoiceModel;
  Object.keys(invoice).forEach((key: string) => {
    const [entity, field] = key.split('.');
    if (!field) {
      result[entity] = invoice[key];
    } else {
      result[field] = invoice[key];
    }
  });
  return result;
};
