import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';

const contentPageStyle = makeStyles(() => ({
  title: {
    flexGrow: 1,
    fontFamily: 'Qanelas-Bold',
  },
  logo: {
    color: 'white',
    textDecoration: 'none',
  },
  arrowContainer: {
    marginLeft: '3px',
    marginRight: '3px',
    display: 'inline-block',
  },
  arrow: {
    position: 'relative',
    top: '1px',
    height: '13px',
  },
}));
//
const Logo = () => {
  const classes = contentPageStyle();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  if (isTabletOrMobile) {
    return (
      <>
        <Typography
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}>
          <a href="https://www.perfectdeed.com" className={classes.logo}>P
            <div className={classes.arrowContainer}>
              <img className={classes.arrow} src="/images/arrow-black.svg" alt="PerfectDeed" />
            </div>D
          </a>
        </Typography>
      </>
    );
  }
  return (
    <Typography
      variant="h6"
      color="inherit"
      noWrap
      className={classes.title}>
      <a href="https://www.perfectdeed.com" className={classes.logo}>Perfect
        <div className={classes.arrowContainer}>
          <img className={classes.arrow} src="/images/arrow-black.svg" alt="PerfectDeed" />
        </div>Deed
      </a>
    </Typography>
  );
};

export default Logo;
//
