/* eslint-disable max-len */

import { API_URL } from 'src/constants/Endpoints';
import makeApiRequest from 'src/lib/makeApiRequest';
import {
  CreateOrganisationPayload,
  OrganisationAddressPayload,
  OrganisationInfoPayload,
  OrganisationModel,
} from 'src/models/OrganisationModel';
import { OrganisationItemModel, OrganisationListModel } from 'src/models/OrganisationListModel';
import {
  UserOrganisationItemModel,
  UserOrganisationListModel,
} from 'src/models/UserOrganisationListModel';

export const getOrganisationData = (organisation: string) => makeApiRequest({
  method: 'GET',
  url: `${API_URL.organisation}/${organisation}`,
  organisation: 'aic',
});

export const getOrganisationFromHash = (hash: string) => makeApiRequest({
  method: 'GET',
  url: `${API_URL.organisationFromHash}/${hash}`,
  organisation: 'aic',
});

export const createOrganisationData = (payload: CreateOrganisationPayload) => makeApiRequest({
  method: 'POST',
  url: `${API_URL.create_organisation}`,
  organisation: 'aic',
  data: payload,
});

export const postOrganisationExistsData = (id: string) => makeApiRequest({
  method: 'POST',
  url: `${API_URL.organisationExists}`,
  organisation: 'aic',
  data: { id },
});

export const normalizeOrganisationData = (data: any): OrganisationModel => (
  {
    id: data.id,
    name: data.name,
    nameEn: data.name_en,
    nameFr: data.name_fr,
    legalName: data.legal_name,
    registrationNumber: data.registration_number,
    website: data.website,
    email: data.email,
    timezoneId: data.timezone_id,
    type: data.type,
    isNpo: data.is_npo,
    isJewish: data.is_jewish,
    address: data.address,
    city: data.city,
    state: data.state,
    suite: data.suite,
    zipCode: data.zip_code,
    countryId: data.country_id,
    phone: data.phone,
    extension: data.extension,
    tollfreePhone: data.tollfree_phone,
    tollfreeExtension: data.tollfree_extension,
  }
);

export const getOrganisationListData = () => makeApiRequest({
  method: 'GET',
  url: `${API_URL.organisations}`,
  organisation: 'aic',
});

export const normalizeOrganisationListData = (data: any): OrganisationListModel => data.reduce((accumulator: OrganisationListModel, item: any) => {
  accumulator[item.id] =
    {
      id: item.id,
      name_fr: item.name_fr,
      name_en: item.name_en,
      hide: item.hide,
    } as OrganisationItemModel;
  return accumulator;
}, {} as OrganisationListModel);

export const getOrganisationsUserData = () => makeApiRequest({
  method: 'GET',
  url: `${API_URL.user_organisations}`,
  organisation: 'aic',
});

export const postOrganisationsUserData = (organisation: string) => makeApiRequest({
  method: 'POST',
  url: `${API_URL.user_organisation}/${organisation}`,
  organisation: 'aic',
});

export const shareInfoOrganisationsUserData = (organisation: string, shareInfo: boolean) => makeApiRequest({
  // TODO add a PUT route on the api
  method: 'PUT',
  url: `${API_URL.user_organisation}/${organisation}/toggle/${shareInfo ? 1 : 0}`,
  organisation: 'aic',
});

export const deleteOrganisationsUserData = (organisation: string) => makeApiRequest({
  method: 'DELETE',
  url: `${API_URL.user_organisation}/${organisation}`,
  organisation: 'aic',
});

export const putOrganisationInfoData = (organisation: string, data: OrganisationInfoPayload) => makeApiRequest({
  method: 'PUT',
  url: `${API_URL.organisation}/info`,
  organisation,
  data,
});

export const putOrganisationAddressData = (organisation: string, data: OrganisationAddressPayload) => makeApiRequest({
  method: 'PUT',
  url: `${API_URL.organisation}/${organisation}`,
  organisation,
  data,
});

export const normalizeOrganisationsUserData = (data: any): UserOrganisationListModel => data.reduce((accumulator: UserOrganisationListModel, item: any) => {
  accumulator[item.id] = {
    id: item.id,
    name_fr: item.name_fr,
    name_en: item.name_en,
    hide: item.hide,
    shareInfo: item.meta.share_info,
    isAdmin: item.meta.is_admin,
  } as UserOrganisationItemModel;
  return accumulator;
}, {} as UserOrganisationListModel);
