import { useState } from 'react';
import UserPageHeader from 'src/components/Elements/User/UserPageHeader';
import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { useMediaQuery } from 'react-responsive';
import {
  FormControl, FormControlLabel, Radio, RadioGroup,
} from '@material-ui/core';
import ManageUserReceipts from 'src/components/Elements/User/ManageUserReceipts';
import UserReceiptSearch from 'src/components/Elements/User/UserReceiptSearch';
import UserDownloadTaxReceiptButton
  from 'src/components/DatagridToolbar/buttons/UserDownloadTaxReceiptButton';

const useStyles = (isTabletOrMobile:boolean) => makeStyles((theme) => ({
  card: {
    border: '1px solid rgba(0, 0, 0, 0.25)',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      cursor: 'pointer',
    },
    flex: 1,
    width: '100%',
    maxWidth: '680px',
  },
  label: {
    fontWeight: 'bold',
    marginRight: '8px',
  },
  selected: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
  list: {
    marginTop: '0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mobile: {
    marginTop: '16px',
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '18px',
  },
  submit: {
    marginLeft: 'auto',
    display: 'flex',
    marginBottom: '8px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    gap: '8px',
    alignItems: 'center',
  },
  col2: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '50%',
    width: '100%',
    alignItems: 'center',
  },
  col3: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '33%',
    width: '100%',
    alignItems: 'center',
  },
  col4: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '25%',
    width: '100%',
    alignItems: 'center',
  },
}));

export enum ReceiptSections {
  purchase = 'purchase',
  tax = 'tax',
}

const UserReceiptsPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const classes = useStyles(isTabletOrMobile)();

  const [section, setSection] = useState(ReceiptSections.tax);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSection((event.target as HTMLInputElement).value as ReceiptSections);
  };

  const showBottomRow = () => (
    <>
      <div className={classes.row} style={{ backgroundColor: '#FFF' }}>
        <div style={{
          maxWidth: '680px',
          width: '100%',
          margin: '0 auto',
        }}>
          <UserReceiptSearch section={section} />
        </div>
      </div>
      <div className={classes.row} style={{ backgroundColor: '#FFF' }}>
        <div style={{
          maxWidth: '680px',
          width: '100%',
          margin: '0 auto',
        }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={section}
              onChange={handleChange}>
              <FormControlLabel value={ReceiptSections.tax} control={<Radio />} label="Tax" />
              <FormControlLabel
                value={ReceiptSections.purchase}
                control={<Radio />}
                label="Purchase" />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.row} style={{ backgroundColor: '#FFF' }}>
          <div style={{
            maxWidth: '680px',
            width: '100%',
            margin: '0 auto',
            marginBottom: '8px',
          }}>
            <div className={classes.row}>
              <UserDownloadTaxReceiptButton />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <UserPageHeader
        title="Receipts"
        bottomRow={showBottomRow()} />
      <div style={{ height: '96px' }} />
      <ManageUserReceipts section={section} />
    </>
  );
};
export default UserReceiptsPage;
