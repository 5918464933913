import { ProductOrderList, ProductOrderModel } from 'src/models/InvoiceModel';
import { INVOICE_STATE } from 'src/constants/Invoices';
import OrderDisplayerHeader from 'src/components/Elements/OrdersDisplayerHeader';
import OrderProductItem from 'src/components/Elements/OrderProductItem';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import { Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { FORM_ID } from 'src/models/FormModel';
import { ProductModel } from 'src/models/ProductModel';

interface Props {
  form: FORM_ID,
  orders: ProductOrderList,
  onAddProduct: () => void,
  onUpdateProduct: (value: Value, field: string, index: number) => void,
  onDeleteProduct: (orderIndex: number) => void,
  onProductChange: (product?: ProductModel) => void,
  invoiceState?: INVOICE_STATE,
  disabled?: boolean,
}

export enum OrderColumn {
  actions = '64px',
  quantity = '96px',
  product = '100%',
  price = '128px',
  taxes = '128px',
  discount = '128px',
  deductible = '128px',
  amount = '128px',
}

const OrdersDisplayer = (props: Props) => {
  const {
    form,
    onAddProduct,
    onUpdateProduct,
    onDeleteProduct,
    orders,
    onProductChange,
    invoiceState,
    disabled,
  } = props;

  const displayOrderProductItem = () => orders.map((order, index) => (
    <OrderProductItem
      canDeleteProduct={orders.length > 1}
      invoiceState={invoiceState}
      form={form}
      index={index}
      onDeleteProduct={onDeleteProduct}
      onUpdateProduct={onUpdateProduct}
      order={order as ProductOrderModel}
      orders={orders as ProductOrderList}
      key={`order-displayer-item-${index}`}
      onProductChange={onProductChange}
      disabled={disabled} />
  ));

  return (
    <div>
      <OrderDisplayerHeader canDeleteProduct={orders.length > 1} disabled={disabled} />
      {displayOrderProductItem()}
      <div style={{ margin: 'auto' }} />
      { (invoiceState !== INVOICE_STATE.final) && (
      <div style={{ width: '100%', height: '32px', marginTop: '8px' }}>
        <Tooltip
          title="Add a product"
          placement="top-start">
          <>
            <ActionButton
              variant="outlined"
              category={MENU_BUTTON_CATEGORY.action}
              onClick={onAddProduct}
              style={{ float: 'right' }}
              disabled={disabled}>
              <AddIcon />
            </ActionButton>
          </>
        </Tooltip>
      </div>
      )}
    </div>
  );
};

export default OrdersDisplayer;
//
