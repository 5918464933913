import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import { CSSProperties } from 'react';

interface Props {
  title: string;
  bottomRow?: any,
}

const useStyles = (isMobile: boolean) => makeStyles((theme) => ({
  header: {
    width: '100%',
    marginBottom: theme.spacing(2),
    position: 'fixed',
    top: '56px',
    right: '0px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
    margin: isMobile ? '0 16px' : '0 auto',
  },
  bottom: {
    alignItems: 'center',
    flexDirection: 'column',

    height: 'auto',
    backgroundColor: 'white',
  },
  buttons: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  footer: {
    textAlign: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  close: {
    fontSize: '0.7rem',
    color: theme.palette.primary.main,
    display: 'inline-block',
    textDecoration: 'underline',
    margin: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const UserPageHeader = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const classes = useStyles(isTabletOrMobile)();

  const {
    bottomRow,
    title,
  } = props;

  const getStyles = () => {
    if (isTabletOrMobile) {
      return {
        maxWidth: '680px',
        padding: '0 8px',
      } as CSSProperties;
    }
    return {
      maxWidth: '680px',
    } as CSSProperties;
  };

  const getBottomStyle = () => {
    if (isTabletOrMobile) {
      return {
        padding: '0 8px',
        paddingBottom: '8px',
      } as CSSProperties;
    }
    return {
      margin: '0 32px',
    };
  };
  //
  return (
    <>
      <div className={classes.header}>
        <div style={{ width: '100%', backgroundColor: '#0A4DF2' }}>
          <div className={classes.row} style={getStyles()}>
            <span className={classes.title}>
              { title }
            </span>
          </div>
        </div>
        <div className={classes.bottom} style={getBottomStyle()}>
          { bottomRow }
        </div>
      </div>
    </>
  );
};

export default UserPageHeader;
