import { Store, useAppDispatch } from 'src/redux/Store';
import { useSelector } from 'react-redux';
import { normalizeInvoiceForUser } from 'src/lib/InvoiceHelper';
import { t } from 'src/lib/language';
import { useMediaQuery } from 'react-responsive';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { setSelections } from 'src/redux/actions/selectionActions';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { INVOICE_PAYMENT_STATUS, INVOICE_PAYMENT_STATUS_COLOR } from 'src/constants/Invoices';
import { SEARCH_LOGICAL_OPERATORS } from 'src/models/AdvanceSearchModel';
import { getEntitiesAction, setFilters } from 'src/redux/actions/querybuilderActions';
import { InvoiceSections } from 'src/components/Pages/UserInvoicesPage';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import { getInvoiceFilter } from 'src/lib/PaymentHelper';
import { InvoiceModel } from 'src/models/InvoiceModel';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import CircularProgress from '@material-ui/core/CircularProgress';
import Row from 'src/components/UI/Row';
import UserCardItem from 'src/components/Elements/User/UserCardItem';
import palette from 'src/styles/palette';
import { normalizeForForm } from 'src/lib/QueryBuilderHelper';

interface Props {
  section: InvoiceSections,
}

const ManageUserInvoices = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const { section } = props;

  const MAX_ITEMS = 7;

  useEffect(() => {
    const start = moment().subtract(2, 'year');
    const end = moment();

    const filters = getInvoiceFilter(
      start,
      end,
    );
    dispatch(setFilters({
      entity: ENTITIES.invoices,
      filters: { [SEARCH_LOGICAL_OPERATORS.and]: filters },
    }));
    dispatch(getEntitiesAction(ENTITIES.invoices));
  }, []);

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'CAD',
  });

  const selections = useSelector((state: Store) => {
    if (!!state.selected[ENTITIES.invoices]) {
      return [...state.selected[ENTITIES.invoices]];
    }
    return [];
  });

  const loading = useSelector(
    (state: Store) => state.formStatus[FORM.query_builder] === FORM_STATUS.processing,
  );

  const invoices = useSelector(
    (state: Store) => state.querybuilder.invoices?.result?.list || [],
  );
  const normalizedInvoices = invoices.map(
    (invoice: any) => normalizeForForm(invoice, ENTITIES.invoices),
  );

  const getFiltered = (): InvoiceModel[] => normalizedInvoices.map((item: any) => {
    const invoice = normalizeInvoiceForUser(item);
    const now = moment();

    if (section === InvoiceSections.overdue) {
      if (invoice.due_date.isAfter(now) ||
        invoice.payment_status === INVOICE_PAYMENT_STATUS.paid ||
        invoice.payment_status === INVOICE_PAYMENT_STATUS.scheduled) {
        return undefined;
      }
    } else if (section === InvoiceSections.unpaid) {
      if (invoice.payment_status === INVOICE_PAYMENT_STATUS.paid ||
        invoice.payment_status === INVOICE_PAYMENT_STATUS.scheduled) {
        return undefined;
      }
    } else if (section === InvoiceSections.paid) {
      if (invoice.payment_status === INVOICE_PAYMENT_STATUS.not_paid ||
        invoice.payment_status === INVOICE_PAYMENT_STATUS.scheduled_due ||
        invoice.payment_status === INVOICE_PAYMENT_STATUS.partially_paid) {
        return undefined;
      }
    }

    return invoice;
  }).filter((item: any): item is InvoiceModel => item !== undefined); // Type guard

  const [page, setPage] = useState(1);
  const [items, setItems] = useState(getFiltered().slice(0, MAX_ITEMS));
  const [hasMore, setHasMore] = useState(true);

  const scrollableDivRef = useRef<HTMLDivElement>(null);

  const resetScroll = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = 0; // Reset vertical scroll
    }
  };

  useEffect(() => {
    if (invoices.length) {
      const filteredItems = getFiltered();
      setPage(1);
      setHasMore(filteredItems.length > MAX_ITEMS);
      setItems(filteredItems.slice(0, MAX_ITEMS));
      resetScroll();
    }
  }, [section, invoices]);

  const handleOnClick = (id?: number) => {
    if (!id) return;
    const index = selections.indexOf(id);

    if (index >= 0) {
      selections.splice(index, 1);
    } else {
      selections.push(id);
    }

    dispatch(setSelections({
      entity: ENTITIES.invoices,
      selections,
    }));
  };

  const handleNext = () => {
    const filteredItems = getFiltered();
    const newItems = filteredItems.slice(page * MAX_ITEMS, (page + 1) * MAX_ITEMS);
    setItems((prevItems) => [...prevItems, ...newItems]);
    setPage((prevState) => prevState + 1);
    setHasMore(filteredItems.length > (page + 1) * MAX_ITEMS);
  };

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    const { scrollTop, scrollHeight, clientHeight } = target;
    // Check if the user has scrolled to the bottom
    if (scrollHeight - scrollTop === clientHeight) {
      if (hasMore) {
        handleNext();
      }
    }
  }, [page, hasMore]);

  const getItemStyle = (id?: number) => {
    if (selections.indexOf(id) < 0) {
      return {
        backgroundColor: '#FFF',
      };
    }
    return {
      border: `1px solid ${palette.primary.main}`,
      backgroundColor: lighten(palette.primary.main, 0.9),
    };
  };

  const getRowStyle = () => {
    if (!isTabletOrMobile) {
      return {
        minWidth: '640px',
      } as CSSProperties;
    }
    return {} as CSSProperties;
  };

  const getItem = (invoice: InvoiceModel) => {
    const isDue = () => {
      switch (invoice.payment_status) {
        case INVOICE_PAYMENT_STATUS.paid:
        case INVOICE_PAYMENT_STATUS.scheduled:
          return false;
        default:
          if (!moment.isMoment(invoice.due_date)) return false;
          return invoice.due_date.isBefore(moment());
      }
    };

    const hasWarning = () => {
      switch (invoice.payment_status) {
        case INVOICE_PAYMENT_STATUS.scheduled_due:
          return true;
        default:
          return false;
      }
    };

    const getStatusColor = () => {
      const color = INVOICE_PAYMENT_STATUS_COLOR[
        invoice.payment_status ?? INVOICE_PAYMENT_STATUS_COLOR.not_paid
      ];
      return hasWarning() ? '#d41d1d' : color;
    };

    const getDate = (value: any) => {
      if (!moment.isMoment(value)) return '';
      return value.format('YYYY-MM-DD');
    };

    const Warning = () => {
      if (!hasWarning() && !isDue()) {
        return (<></>);
      }
      return (
        <WarningIcon style={{
          color: '#d41d1d',
          marginRight: 0,
          marginLeft: 'auto',
        }} />
      );
    };

    return (
      <Row style={getRowStyle()} key={Math.random()}>
        <UserCardItem
          style={getItemStyle(invoice.id)}
          onClick={() => handleOnClick(invoice.id)}
          itemNumber={Math.random()}>
          <div><b>{invoice.invoice_number}</b></div>
          <Row>
            <span><b>{invoice.title}</b></span><Warning />
          </Row>
          <div style={{ color: isDue() ? '#d41d1d' : undefined }}>
            Due date: <span>{getDate(invoice.due_date)}</span>
          </div>
          <div>
            Amount due: <span>{formatter.format(invoice.total ?? 0)}</span>
          </div>
          <div>
            Amount paid: <span>{formatter.format(invoice.paid ?? 0)}</span>
          </div>
          <div>
            Balance: <span>{formatter.format(invoice.balance ?? 0)}</span>
          </div>
          <div>
            Scheduled: <span>{formatter.format(invoice.scheduled ?? 0)}</span>
          </div>
          <div>
            <i>Deductible: <span>{formatter.format(invoice.deductible ?? 0)}</span></i>
          </div>
          <Row>
            <span style={{ color: getStatusColor() }}>
              {t(lang, `forms.invoices.${invoice.payment_status}`)}
            </span>
          </Row>
        </UserCardItem>
      </Row>
    );
  };

  const showItems = () => {
    if (loading) {
      return (
        <div style={{ width: '40px', margin: '0 auto', marginTop: '32px' }}>
          <CircularProgress size={40} />
        </div>
      );
    }
    return items.map((item) => getItem(item as InvoiceModel));
  };

  const getListStyle = () => {
    if (isTabletOrMobile) {
      return {
        marginTop: '48px',
        overflowY: 'scroll',
        height: '621px',
      } as CSSProperties;
    }
    return {
      marginTop: '0px',
      overflowY: 'scroll',
      maxWidth: '680px',
      margin: 'auto',
      height: '621px',
    } as CSSProperties;
  };

  const displayItems = () => (
    <div style={getListStyle()} onScroll={handleScroll} ref={scrollableDivRef}>
      {showItems()}
    </div>
  );

  return displayItems();
};

export default ManageUserInvoices;
