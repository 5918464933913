import { CSSProperties, Key, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import palette from 'src/styles/palette';

interface Props {
  children: ReactNode,
  itemNumber?: Key,
  style?: CSSProperties,
  onClick?: () => void
}

const useStyles = makeStyles(() => ({
  card: {
    border: '1px solid rgba(0, 0, 0, 0.25)',
    marginBottom: '8px',
    padding: '8px',
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid ${palette.primary.main}`,
      cursor: 'pointer',
    },
    flex: 1,
    width: '100%',
    maxWidth: '680px',
  },
}));

const UserCardItem = (props: Props) => {
  const classes = useStyles();
  const {
    children, itemNumber, onClick, style,
  } = props;

  return (
    <div
      onClick={onClick}
      className={classes.card}
      style={style}
      key={itemNumber}>
      {children}
    </div>
  );
};

export default UserCardItem;
