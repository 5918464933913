import { makeStyles } from '@material-ui/core/styles';

const useStyles = (noMargin?: boolean) => makeStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.contrastText,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    marginBottom: noMargin ? 0 : theme.spacing(1),
  },
  selected: {
    textDecoration: 'underline',
  },
}));

interface Props {
  onClick?: () => void,
  children: any,
  selected?: boolean,
  noMargin?: boolean,
  hasError?: boolean,
  disabled?: boolean,
  color?: string,
  testId?: string,
}

const SubmenuLink = (props: Props) => {
  const {
    onClick, children, selected, noMargin, hasError, disabled, color, testId,
  } = props;
  const classes = useStyles(noMargin)();

  const onClickHandler = () => {
    if (!onClick || disabled) return;
    onClick();
  };

  const getColor = () => {
    if (disabled) {
      return {
        color: '#555',
      };
    }
    if (hasError) {
      return {
        color: '#F00',
      };
    }
    if (color) {
      return {
        color,
      };
    }
    return undefined;
  };

  return (
    <span
      data-testid={testId}
      className={`${classes.link} ${selected ? classes.selected : ''}`}
      style={getColor()}
      onClick={onClickHandler}>
      {children}
    </span>
  );
};

export default SubmenuLink;
