import { State } from 'src/redux/reducers/RootReducer';
import {
  deleteBankAccountData,
  deleteContactCreditCardData,
  getContactBankAccountData,
  getContactCreditCardData,
  getOrganisationBankAccountData, getUserBankAccountData, getUserCreditCardData,
  postContactBankAccountData,
  postContactCreditCardData,
  postOrganisationBankAccountData,
  putBankAccountData, putContactCreditCardData,
} from 'src/apis/PaymentInfoAPI';
import {
  CreditCardModel,
  CreditCardPayload, UserCreditCardModel,
  UserCreditCardPayload,
} from 'src/models/CreditCardModel';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { setFormStatus } from 'src/redux/actions/formActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import makeActionCreator from 'src/lib/makeActionCreator';
import {
  BankAccountModel,
  BankAccountPayload,
  UserBankAccountModel,
} from 'src/models/BankAccountModel';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { setError } from 'src/redux/actions/errorsActions';
import Debug from 'src/lib/Debug';

export const ADD_CREDITCARD = 'ADD_CREDITCARD';
export const DELETE_CREDITCARD = 'DELETE_CREDITCARD';
export const SET_CREDITCARDS = 'SET_CREDITCARDS';

export const ADD_USER_CREDITCARD = 'ADD_USER_CREDITCARD';
export const DELETE_USER_CREDITCARD = 'DELETE_USER_CREDITCARD';
export const SET_USER_CREDITCARDS = 'SET_USER_CREDITCARDS';

export const SET_CONTACT_BANK_ACCOUNTS = 'SET_CONTACT_BANK_ACCOUNTS';
export const ADD_CONTACT_BANK_ACCOUNT = 'ADD_CONTACT_BANK_ACCOUNT';
export const DELETE_CONTACT_BANK_ACCOUNT = 'DELETE_CONTACT_BANK_ACCOUNT';

export const SET_USER_BANK_ACCOUNTS = 'SET_USER_BANK_ACCOUNTS';
export const ADD_USER_BANK_ACCOUNT = 'ADD_USER_BANK_ACCOUNT';
export const DELETE_USER_BANK_ACCOUNT = 'DELETE_USER_BANK_ACCOUNT';

export const ADD_ORGANISATION_BANK_ACCOUNT = 'ADD_ORGANISATION_BANK_ACCOUNT';
export const SET_ORGANISATION_BANK_ACCOUNTS = 'SET_ORGANISATION_BANK_ACCOUNTS';
export const DELETE_ORGANISATION_BANK_ACCOUNT = 'DELETE_ORGANISATION_BANK_ACCOUNT';
export const UPDATE_ORGANISATION_BANK_ACCOUNT = 'UPDATE_ORGANISATION_BANK_ACCOUNT';

export const addCreditCard = makeActionCreator(ADD_CREDITCARD);
export const deleteCreditCard = makeActionCreator(DELETE_CREDITCARD);
export const setCreditCards = makeActionCreator(SET_CREDITCARDS);

export const addUserCreditCard = makeActionCreator(ADD_USER_CREDITCARD);
export const deleteUserCreditCard = makeActionCreator(DELETE_USER_CREDITCARD);
export const setUserCreditCards = makeActionCreator(SET_USER_CREDITCARDS);

export const setContactBankAccounts = makeActionCreator(SET_CONTACT_BANK_ACCOUNTS);
export const addContactBankAccount = makeActionCreator(ADD_CONTACT_BANK_ACCOUNT);
export const deleteContactBankAccount = makeActionCreator(DELETE_CONTACT_BANK_ACCOUNT);

export const setUserBankAccounts = makeActionCreator(SET_USER_BANK_ACCOUNTS);
export const addUserBankAccount = makeActionCreator(ADD_USER_BANK_ACCOUNT);
export const deleteDeleteBankAccount = makeActionCreator(DELETE_USER_BANK_ACCOUNT);

export const setOrganisationBankAccounts = makeActionCreator(SET_ORGANISATION_BANK_ACCOUNTS);
export const addOrganisationBankAccount = makeActionCreator(ADD_ORGANISATION_BANK_ACCOUNT);
export const deleteOrganisationBankAccount = makeActionCreator(DELETE_ORGANISATION_BANK_ACCOUNT);
export const updateOrganisationBankAccount = makeActionCreator(UPDATE_ORGANISATION_BANK_ACCOUNT);

const normalizeContactCreditCards = (data: any) => data.map((cc: any) => ({
  id: cc.id,
  expiration_data: cc.expiration_data,
  name: cc.name,
  card_holder: cc.card_holder,
  masked_card_number: cc.masked_card_number.replaceAll('X', '*'),
} as CreditCardModel));

const normalizeContactCreditCard = (data: any) => ({
  id: data.id,
  expiration_data: data.expiration_data,
  name: data.name,
  card_holder: data.card_holder,
  masked_card_number: data.masked_card_number,
} as CreditCardModel);

const normalizeBankAccount = (account: any) => ({
  id: account.id,
  bank: account.bank,
  bank_name: account.bank_name,
  bank_holder_name: account.bank_holder_name,
  branch: account.branch,
  account: account.account,
  bank_account_type: account.bank_account_type,
  bank_country: account.bank_country,
  currency_id: account.currency_id,
} as BankAccountModel);

const normalizeContactBankAccounts = (data: any) => data.map(
  (account: any) => normalizeBankAccount(account),
);

// USER
export const getUserCreditCardAction: () => ThunkedAction<State> =
  () => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      dispatch(setFormStatus({ [FORM.get_user_credit_cards]: FORM_STATUS.processing }));
      const response = await getUserCreditCardData(organisation);
      const { success, data } = response;
      if (success) {
        const creditcards = data ? normalizeContactCreditCards(data) : [] as UserCreditCardModel;
        dispatch(setUserCreditCards(creditcards));
        dispatch(setFormStatus({ [FORM.get_user_credit_cards]: FORM_STATUS.success }));
        return;
      }
      dispatch(setFormStatus({ [FORM.get_user_credit_cards]: FORM_STATUS.error }));
    } catch (e) { /* Log the error here */
      dispatch(setFormStatus({ [FORM.get_user_credit_cards]: FORM_STATUS.error }));
    }
  };

export const postUserCreditCardAction:
(payload: UserCreditCardPayload) => ThunkedAction<State> =
  (payload: UserCreditCardPayload) => async (dispatch: any, getState: any) => {
    dispatch(setFormStatus({ [FORM.add_creditcard]: FORM_STATUS.processing }));
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await postContactCreditCardData(organisation, payload);
      const { success, data } = response;
      if (success) {
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.credit_card_added',
        }));
        dispatch(setFormStatus({ [FORM.add_creditcard]: FORM_STATUS.success }));
        dispatch(addUserCreditCard(normalizeContactCreditCard(data)));
        dispatch(closeModal({ modal: MODALS.addCreditCard }));
        return;
      }
      if (response.status === 400) {
        dispatch(setError({ [FORM.add_creditcard]: response.errors.fields }));
        dispatch(setFormStatus({ [FORM.add_creditcard]: FORM_STATUS.error }));
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: Object.values(response.errors).join('. '),
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.credit_card_added_error',
      }));
      dispatch(setFormStatus({ [FORM.add_creditcard]: FORM_STATUS.error }));
    } catch (e) { /* Log the error here */
      dispatch(setFormStatus({ [FORM.add_creditcard]: FORM_STATUS.error }));
    }
  };

// CONTACT
export const getContactCreditCardAction: (id: number) => ThunkedAction<State> =
  (id: number) => async (dispatch: any, getState: any) => {
    if (!id) {
      return;
    }
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await getContactCreditCardData(organisation, id);
      const { success, data } = response;
      if (success) {
        const payload = {
          id,
          creditCards: normalizeContactCreditCards(data),
        };
        dispatch(setCreditCards(payload));
      }
    } catch (e) { /* Log the error here */
      Debug.log(e);
    }
  };

export const postContactCreditCardAction:
(payload: CreditCardPayload) => ThunkedAction<State> =
  (payload: CreditCardPayload) => async (dispatch: any, getState: any) => {
    dispatch(setFormStatus({ [FORM.add_creditcard]: FORM_STATUS.processing }));
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await postContactCreditCardData(organisation, payload);
      const { success, data } = response;
      if (success) {
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.credit_card_added',
        }));
        dispatch(setFormStatus({ [FORM.add_creditcard]: FORM_STATUS.success }));
        dispatch(addCreditCard({ id: payload.contact_id, card: normalizeContactCreditCard(data) }));
        dispatch(closeModal({ modal: MODALS.addCreditCard }));
        return;
      }
      if (response.status === 400) {
        dispatch(setError({ [FORM.add_creditcard]: response.errors.fields }));
        dispatch(setFormStatus({ [FORM.add_creditcard]: FORM_STATUS.error }));
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: Object.values(response.errors).join('. '),
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.credit_card_added_error',
      }));
      dispatch(setFormStatus({ [FORM.add_creditcard]: FORM_STATUS.error }));
    } catch (e) { /* Log the error here */
      dispatch(setFormStatus({ [FORM.add_creditcard]: FORM_STATUS.error }));
    }
  };

export const putContactCreditCardAction:
(payload: CreditCardPayload, creditCardId: number) => ThunkedAction<State> =
  (payload: CreditCardPayload, creditCardId: number) => async (dispatch: any, getState: any) => {
    dispatch(setFormStatus({ [FORM.edit_creditcard]: FORM_STATUS.processing }));
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await putContactCreditCardData(organisation, payload, creditCardId);
      const { success, data } = response;
      if (success) {
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.credit_card_added',
        }));
        dispatch(setFormStatus({ [FORM.edit_creditcard]: FORM_STATUS.success }));
        dispatch(addCreditCard({ id: payload.contact_id, card: normalizeContactCreditCard(data) }));
        dispatch(closeModal({ modal: MODALS.editCreditCard }));
        return;
      }
      if (response.status === 400) {
        dispatch(setError({ [FORM.add_creditcard]: response.errors.fields }));
        dispatch(setFormStatus({ [FORM.edit_creditcard]: FORM_STATUS.error }));
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: Object.values(response.errors).join('. '),
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.credit_card_added_error',
      }));
      dispatch(setFormStatus({ [FORM.edit_creditcard]: FORM_STATUS.error }));
    } catch (e) { /* Log the error here */
      dispatch(setFormStatus({ [FORM.edit_creditcard]: FORM_STATUS.error }));
    }
  };

export const deleteContactCreditCardAction:
(contact_id: number, card_id: number) => ThunkedAction<State> =
  (contact_id: number, card_id: number) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await deleteContactCreditCardData(organisation, card_id);
      if (response.success) {
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.credit_card_deleted',
        }));
        dispatch(deleteCreditCard({ contactId: contact_id, cardId: contact_id }));
        dispatch(getContactCreditCardAction(contact_id));
        dispatch(closeDialog({ dialog: DIALOGS.deleteCreditCard }));
        return;
      }
      if (response.message) {
        const errorNumber = response.message.split(' - ')[0];
        if (errorNumber === '9001') {
          dispatch(setAlert({
            type: ALERT_TYPE.error,
            code: 'errors.9001',
          }));
        }
      } else {
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.credit_card_deleted_error',
        }));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const getContactBankAccountAction: (id: number) => ThunkedAction<State> =
  (id: number) => async (dispatch: any, getState: any) => {
    if (!id) {
      return;
    }
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await getContactBankAccountData(organisation, id);
      const { success, data } = response;
      if (success) {
        if (success) {
          dispatch(setContactBankAccounts({
            id,
            bankAccounts: normalizeContactBankAccounts(data),
          }));
        }
      }
    } catch (e) { /* Log the error here */
    }
  };

export const getUserBankAccountAction: () => ThunkedAction<State> =
  () => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      dispatch(setFormStatus({ [FORM.get_user_credit_cards]: FORM_STATUS.processing }));
      const response = await getUserBankAccountData(organisation);
      const { success, data } = response;
      if (success) {
        if (success) {
          const bankAccounts =
            data ? normalizeContactBankAccounts(data) : [] as UserBankAccountModel;
          dispatch(setUserBankAccounts(bankAccounts));
          dispatch(setFormStatus({ [FORM.get_user_credit_cards]: FORM_STATUS.success }));
        }
      }
      dispatch(setFormStatus({ [FORM.get_user_credit_cards]: FORM_STATUS.error }));
    } catch (e) { /* Log the error here */
      dispatch(setFormStatus({ [FORM.get_user_credit_cards]: FORM_STATUS.error }));
    }
  };

export const postContactBankAccountAction:
(payload: BankAccountPayload) => ThunkedAction<State> =
  (payload: BankAccountPayload) => async (dispatch: any, getState: any) => {
    if (!payload.contact_id) return;
    dispatch(setFormStatus({ [FORM.add_bankaccount]: FORM_STATUS.processing }));
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await postContactBankAccountData(organisation, payload.contact_id, payload);
      const { success, data } = response;
      if (success) {
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.bank_account_added',
        }));
        dispatch(setFormStatus({ [FORM.add_bankaccount]: FORM_STATUS.success }));
        dispatch(addContactBankAccount({
          id: payload.contact_id,
          bankAccount: normalizeBankAccount(data),
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.bank_account_added_error',
      }));
      dispatch(setFormStatus({ [FORM.add_bankaccount]: FORM_STATUS.error }));
    } catch (e) { /* Log the error here */
      dispatch(setFormStatus({ [FORM.add_bankaccount]: FORM_STATUS.error }));
    }
  };

export const getOrganisationBankAccountAction: () => ThunkedAction<State> =
  () => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await getOrganisationBankAccountData(organisation);
      const { success, data } = response;
      if (success) {
        dispatch(setOrganisationBankAccounts({
          id: organisation,
          bankAccounts: normalizeContactBankAccounts(data),
        }));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const postOrganisationBankAccountAction:
(payload: BankAccountPayload) => ThunkedAction<State> =
  (payload: BankAccountPayload) => async (dispatch: any, getState: any) => {
    dispatch(setFormStatus({ [FORM.add_organisation_bankaccount]: FORM_STATUS.processing }));
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await postOrganisationBankAccountData(organisation, payload);
      const { success, data } = response;
      if (success) {
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.bank_account_added',
        }));
        dispatch(setFormStatus({ [FORM.add_organisation_bankaccount]: FORM_STATUS.success }));
        dispatch(addOrganisationBankAccount({
          id: organisation,
          bankAccount: normalizeBankAccount(data),
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.bank_account_added_error',
      }));
      dispatch(setFormStatus({ [FORM.add_organisation_bankaccount]: FORM_STATUS.error }));
    } catch (e) { /* Log the error here */
      dispatch(setFormStatus({ [FORM.add_organisation_bankaccount]: FORM_STATUS.error }));
    }
  };

export const updateBankAccountAction:
(id: number, payload: BankAccountPayload) => ThunkedAction<State> =
  (id: number, payload: BankAccountPayload) => async (dispatch: any, getState: any) => {
    dispatch(setFormStatus({ [FORM.put_bank_account]: FORM_STATUS.processing }));

    try {
      const organisation = getState().currentOrganisation.id;
      const response = await putBankAccountData(id, payload, organisation);
      const { success, data } = response;
      if (success) {
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.bank_account_updated',
        }));
        dispatch(setFormStatus({ [FORM.put_bank_account]: FORM_STATUS.success }));
        dispatch(updateOrganisationBankAccount({
          id: organisation,
          bankAccount: normalizeBankAccount(data),
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.bank_account_updated_error',
      }));
      dispatch(setFormStatus({ [FORM.put_bank_account]: FORM_STATUS.error }));
    } catch (e) { /* Log the error here */
      dispatch(setFormStatus({ [FORM.put_bank_account]: FORM_STATUS.error }));
    }
  };

export const deleteBankAccountAction:
(account_id: number, contact_id?: number) => ThunkedAction<State> =
  (account_id: number, contact_id?: number) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await deleteBankAccountData(organisation, account_id);
      if (response.success) {
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.bank_account_deleted',
        }));

        if (!!contact_id) {
          dispatch(deleteContactBankAccount({ contactId: contact_id, accountId: account_id }));
          dispatch(getContactBankAccountAction(contact_id));
        } else {
          deleteOrganisationBankAccount({ accountId: account_id, organisation });
          dispatch(getOrganisationBankAccountAction());
        }

        dispatch(closeDialog({ dialog: DIALOGS.deleteBankAccount }));
        return;
      }
      if (response.message) {
        const errorNumber = response.message.split(' - ')[0];
        if (errorNumber === '9001') {
          dispatch(setAlert({
            type: ALERT_TYPE.error,
            code: 'errors.9001',
          }));
        }
      } else {
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.bank_account_deleted_error',
        }));
      }
    } catch (e) { /* Log the error here */
    }
  };
